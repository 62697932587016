import React from "react";
import styled from "styled-components";

export const ScheduleInfo = () => {
  return (
    <Container>
      <Info label="Schedule ID" value="5643gfds432543245353" />
      <Info label="Status" value="Errored" />
      <Info label="Retailer" value="Walmart" />
      <Info label="Domain" value="http://walmart.com" />
      <Info label="Scrape Template" value="5643gfds432543245353" />
      <Info label="Parse Template" value="5643gfds432543245353" />
    </Container>
  );
};

const Container = styled.div`
  height: 80px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 3px;
  padding: 0 24px;
  & > div:nth-child(1) {
    width: 320px;
  }
  & > div:nth-child(2) {
    width: 120px;
  }
  & > div:nth-child(3) {
    width: 120px;
  }
  & > div:nth-child(4) {
    flex: 1;
  }
  & > div:nth-child(5) {
    width: 320px;
  }
  & > div:nth-child(6) {
    width: 320px;
  }
`;

const Info = ({ label, value, className }) => {
  return (
    <InfoContainer className={className}>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </InfoContainer>
  );
};
const InfoContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  margin: auto 16px auto 0;
  .label {
    font-size: 12px;
    color: ${(props) => props.theme.palette.secondary};
  }
  .value {
    color: white;
    font-size: 20px;
  }
`;
