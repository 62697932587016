import React from "react";
import RiseLoader2 from "react-spinners/RiseLoader";
import { css as EmotionCSS } from "@emotion/react";

export const RiseLoader = ({ className }) => {
  return (
    <div className={className}>
      <RiseLoader2
        size={20}
        margin={0}
        css={EmotionCSS`
          display: flex;
          margin: 40px auto;
        `}
        color="#006280"
      />
    </div>
  );
};
