/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import { COUNT_PER_PAGE } from "config";
import AlertsReducer from "./AlertsReducer";
import {
  SET_LOADING,
  INIT_ALERTS,
  GET_ALERTS,
  SET_ERROR,
  GET_MORE_ALERTS,
  SET_ALERTS_SEARCH,
  SET_ALERTS_FILTER,
  SET_REFETCHING,
} from "../types";
import { getAlertsApi } from "api";
const AlertContext = createContext();

export const useAlertContext = () => useContext(AlertContext);

const STORAGE_KEY = "armitage-alert-state";

export const AlertProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    alerts: [],
    scheduler: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    archiveDateFrom: "",
    archiveDateTo: "",
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(AlertsReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getAlerts = async () => {
    setAlertsLoading();
    const res = await getAlertsApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      retailer: (state?.retailer || []).join(","),
      message: (state?.message || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      silence_time_from: state?.silenceDateFrom,
      silence_time_to: state?.silenceDateTo,
    });
    dispatch({
      type: res.success ? GET_ALERTS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setAlertsRefetching();
    const res = await getAlertsApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      retailer: (state?.retailer || []).join(","),
      message: (state?.message || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      silence_time_from: state?.silenceDateFrom,
      silence_time_to: state?.silenceDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_ALERTS : SET_ERROR,
      payload: res.payload,
    });
  };
  const refetchAlerts = async () => {
    const res = await getAlertsApi();
    dispatch({
      type: res.success ? GET_ALERTS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initAlerts = async () => {
    dispatch({
      type: INIT_ALERTS,
    });
  };

  // Set Loading
  const setAlertsLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });
  // Set Refetching
  const setAlertsRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    console.log("trigger");
    dispatch({ type: SET_ALERTS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    console.log("filter", params);
    dispatch({ type: SET_ALERTS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <AlertContext.Provider
      value={{
        ...state,
        initAlerts,
        setAlertsLoading,
        getAlerts,
        refetchAlerts,
        loadMore,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
