import { API } from "utils";

export const searchScrapeTypesApi = async (search) => {
  try {
    const response = await API().get("scrape_type", {
      params: { search },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
