import React from "react";
import { times } from "lodash";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button, ScrollView } from "components";
import { Transition } from "react-transition-group";
import { useAlertContext } from "contexts";
import { useMutation } from "react-query";
import { format } from "date-fns";
import ReactModal from "react-modal";
import { navigate } from "@reach/router";
import { FaBell } from "react-icons/fa";
import { updateAlertApi } from "api";

const customStyles = (state) => {
  return {
    content: {
      padding: 0,
      borderRadius: 0,
      border: "none",
      height: "100%",
      width: "fit-content",
      top: 0,
      left: "calc(100% + 20px)",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.50)",
      transform:
        state === "entering" || state === "entered"
          ? "translateX(-440px)"
          : state === "exiting" || state === "exited"
          ? "translateX(0px)"
          : "translateX(0px)",
      transition: "all 300ms ease-in-out",
      opacity: state === "entering" || state === "entered" ? 1 : 0,
    },
    overlay: {
      background: "#0A0A0Aa0",
      transition: "all 300ms ease-in-out",
    },
  };
};
export const AlertsModal = ({
  open,
  onClose,
  userName,
  onSearch = () => {},
  isLoading,
  totalCount,
  alerts = [],
}) => {
  const handleNavigation = () => {
    navigate(`/alerts`);
  };

  return (
    <Transition in={open} timeout={300}>
      {(state) => (
        <ReactModal
          closeTimeoutMS={300}
          isOpen={open}
          style={customStyles(state)}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick
        >
          <Container>
            <Header>
              <div className="my-auto">
                <FaBell color="white" size={20} />
              </div>
              <span className="my-auto ml-2">
                {`${isLoading ? "" : totalCount} Alerts`}{" "}
              </span>
              <Button
                className="ml-auto my-auto"
                buttonTheme="dark"
                width="140px"
                size="small"
              >
                Mark all read
              </Button>
            </Header>
            <Content>
              <Link onClick={handleNavigation}>View all alerts</Link>
              <ScrollView>
                {isLoading
                  ? times(7, String).map((index) => (
                      <Alert key={`${index}key`} isLoading />
                    ))
                  : alerts.map((item, index) => (
                      <Alert
                        isLoading={isLoading}
                        data={item}
                        key={`${index}key`}
                      />
                    ))}
              </ScrollView>
            </Content>
          </Container>
        </ReactModal>
      )}
    </Transition>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 420px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 10px 20px;
`;
const Content = styled.div`
  padding: 20px 20px 16px 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Link = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.white};
  font-size: 11px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.primaryOutline};
  }
`;

const Alert = ({ isLoading, data }) => {
  const updateMutation = useMutation((data) => updateAlertApi(data));
  const { getAlerts } = useAlertContext();

  const handleSilence = async () => {
    await updateMutation.mutateAsync(data?.alarm_id);
    getAlerts();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} />
          <Skeleton height={32} />
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <AlertContainer onClick={handleSilence}>
      <div className="d-flex">
        <Icon>
          <IconError>!</IconError>
        </Icon>
        <Message>
          <Retailer>
            {data?.retailer_name ? data?.retailer_name : "No Retailer"}
          </Retailer>
          <Error>{data?.message}</Error>
        </Message>
        <CreationDate>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </CreationDate>
      </div>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  transition: all 300ms ease;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.itemsHover};
  }
`;
const Icon = styled.div`
  margin-right: 8px;
  width: 30px;
  display: flex;
  position: relative;
  svg {
    font-size: 24px;
    margin: auto;
  }
`;
const IconError = styled.div`
  position: absolute;
  background: ${(props) => props.theme.palette.error};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  right: 0;
  top: 6px;
`;
const CreationDate = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.palette.white};
`;

const Retailer = styled.div`
  color: white;
  font-size: 18px;
`;
const Error = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-size: 12px;
`;
const Message = styled.div`
  flex: 1;
  padding: 0 5px;
`;
