import { API } from "utils";

export const getPilotSetsApi = async (params) => {
  try {
    const response = await API().get("pilots", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPilotSetById = async (id) => {
  try {
    const response = await API().get(`pilot/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const searchPilotSetsApi = async (search) => {
  try {
    const response = await API().get("pilots", {
      params: {
        search,
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createPilotSetsApi = async (data) => {
  try {
    const response = await API().post("pilot", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updatePilotSetsApi = async (data) => {
  try {
    const response = await API().put(`pilot/${data?.id}`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deletePilotSetById = async (id) => {
  try {
    const response = await API().delete(`pilot/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
