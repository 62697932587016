import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  Button,
  Input2,
  Editor,
  Editor2,
  Alert,
  AutoComplete,
  DropDown2,
} from "components";
import { useParams, navigate } from "@reach/router";
import {
  getParseTemplateById,
  testParseTemplateApi,
  searchRetailersApi,
  searchScrapeTypesApi,
  searchMatchFiltersApi,
  publishTemplateApi,
  createParseTemplateApi,
  deleteParseTemplateApi,
  updateParseTemplateName,
  getFieldsApi,
} from "api";
import { useMutation, useQuery } from "react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isJSON } from "utils";
import { useInput, useModal } from "hooks";
import { omit } from "lodash";
import { SimpleFormContent } from "./components";

export const ParsingPage = () => {
  const params = useParams();
  const validationModal = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const parseMutation = useMutation((data) => testParseTemplateApi(data));
  const publishMutation = useMutation((data) => publishTemplateApi(data));
  const createMutation = useMutation((data) => createParseTemplateApi(data));
  const archiveParseMutation = useMutation((data) =>
    deleteParseTemplateApi(data)
  );
  const updateParseTemplateNameMutation = useMutation((data) =>
    updateParseTemplateName(data)
  );

  const statusValue = useInput("");
  const url = useInput("");
  const template = useInput("[]");
  const retailer = useInput("");
  const scrapeType = useInput("");
  const response = useInput("");
  const name = useInput("");
  const matchFilter = useInput("");
  const [retailerName, setRetailerName] = useState("");
  const [scrapeTypeName, setScrapeTypeName] = useState("");
  const timeout = useInput("");
  const [inputType, setInputType] = useState("form");
  const [formData, setFormData] = useState([]);

  const { data: matchFilterData } = useQuery(
    ["parse-template-match-filter", retailer.value, scrapeType.value],
    () =>
      searchMatchFiltersApi({
        retailer: retailer.value,
        scrape_type: scrapeType.value,
      }),
    {
      keepPreviousData: true,
      enabled: retailer.value > 0 && scrapeType.value > 0,
    }
  );

  const {
    isLoading: isFieldsLoading,
    isError: isFieldsError,
    data: fieldsData,
    error: fieldsError,
  } = useQuery("fields", getFieldsApi);

  const init = async () => {
    setIsLoading(true);
    let res = {};
    res = await getParseTemplateById(params.id);
    setFormData(res?.contents.map((item, index) => ({ ...item, id: index })));
    template.onChange(
      JSON.stringify(
        res?.contents.map((item) => omit(item, ["id"])),
        null,
        2
      )
    );
    console.log("contents", res?.contents);

    statusValue.setValue(res?.status);
    name.setValue(res?.name);
    setRetailerName(res?.retailer_name);
    setScrapeTypeName(res?.scrape_type_name);
    setIsLoading(false);
    retailer.setValue(res?.retailer_id);
    scrapeType.setValue(res?.scrape_type_id);
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const onChangeNameSave = async () => {
    var timeoutID;
    if (name.value !== "" && params && params.id) {
      if (timeout.value) {
        if (timeout.value !== "init") {
          window.clearTimeout(timeout.value);
        }

        window.clearTimeout(timeout.value);
        timeoutID = window.setTimeout(async () => {
          const resp = await updateParseTemplateNameMutation.mutateAsync({
            name: name.value,
            parse_template_id: params.id,
          });
          console.log("Name Updated", resp);
        }, 3000);
        timeout.setValue(timeoutID);
      } else {
        timeout.setValue("init");
      }
    }
  };

  useEffect(() => {
    onChangeNameSave();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name.value]);

  const handlePublish = async () => {
    const resp = await publishMutation.mutateAsync({
      match_filter_id: matchFilter.value,
      parse_template_id: params.id,
    });
    console.log("publish", resp);
  };
  const handleParse = async () => {
    const resp = await parseMutation.mutateAsync({
      url: url.value,
      scrape_type_id: scrapeType.value,
      template: JSON.parse(template.value),
    });
    console.log("parse", resp);
    response.setValue(JSON.stringify(resp, null, 2));
  };
  const handleValidation = () => {
    console.log("template", isJSON(template.value));
    validationModal.openModal();
  };

  const handleSave = async () => {
    const resp = await createMutation.mutateAsync({
      retailer_id: retailer.value,
      scrape_type_id: scrapeType.value,
      name: name.value,
      version: 1,
      status: "NEW",
      parse_variables: "{}",
      parse_template: JSON.parse(template.value),
      user_id: 1,
      parse_template_id: params.id,
    });
    if (resp?.parse_template_id !== params.id) {
      navigate(`/parse_template/${resp?.parse_template_id}`);
    }
  };

  const handleInitialize = () => {
    const maxId = Math.max(...formData.map((a) => a?.id));
    const data = [
      ...formData,
      ...fieldsData
        .filter((field) => field.initialize === "YES")
        .map((row, index) =>
          row.field_type === "section" || row.field_type === "sectionfield"
            ? {
                section: row.name,
                section_path: "",
                section_type: "",
                block_path: "",
                block_type: "",
                endpoint: "",
                blocks: [{
                  "field": "href",
                  "type": "",
                  "path": "",
                },
                {
                  "field": "image",
                  "type": "",
                  "path": "",
                },
                {
                  "field": "name",
                  "type": "",
                  "path": "",
                },
                {
                  "field": "price",
                  "type": "",
                  "path": "",
                }],
                id: maxId + index + 1,
              }
            : {
                field: row.display_name,
                type: "",
                path: "",
                endpoint: "",
                id: maxId + index + 1,
              }
        ),
    ];
    console.log("data", data);
    setFormData(data);
    template.onChange(
      JSON.stringify(
        data.map((item) => omit(item, ["id"])),
        null,
        2
      )
    );
  };

  const handleArchive = async () => {
    await archiveParseMutation.mutateAsync(params.id);
    navigate(`/parse_templates`);
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout activeTab="PARSE TEMPLATES">
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <Input2
                label="Template Name"
                className="col-6 my-auto"
                {...name}
              />

              <AutoComplete
                label="Retailer"
                className="col-3"
                {...retailer}
                idField="retailer_id"
                func={searchRetailersApi}
                displayValue={retailerName}
              />
              <AutoComplete
                label="Scrape Type"
                className="flex-1"
                {...scrapeType}
                idField="scrape_type_id"
                func={searchScrapeTypesApi}
                displayValue={scrapeTypeName}
              />

              {statusValue?.value !== "ARCHIVE" ? (
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-3 my-auto"
                  onClick={handleArchive}
                >
                  Archive
                </Button>
              ) : (
                ""
              )}
            </div>
          )}
        </Header>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <ToolbarContainer className="d-flex">
              <div className="d-flex my-auto">
                <Skeleton height={32} width={800} />
              </div>
            </ToolbarContainer>
          </SkeletonTheme>
        ) : (
          <ToolbarContainer>
            <div className="row w-100">
              <Input2 label="URL Match" className="col-6 my-auto" {...url} />
              <DropDown2
                label="Match Filter"
                className="col-3 my-auto"
                {...matchFilter}
                data={matchFilterData || []}
              />

              <Input2
                label="Status"
                className="flex-1 my-auto"
                {...statusValue}
              />
              {statusValue?.value !== "ARCHIVE" ? (
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-3 mt-auto mb-3"
                  onClick={handlePublish}
                  disabled={!matchFilter.value}
                  isLoading={publishMutation.isLoading}
                >
                  Publish
                </Button>
              ) : (
                ""
              )}
            </div>
          </ToolbarContainer>
        )}
        <MainContent>
          <SideSection>
            <SimpleFormContent
              show={inputType === "form"}
              data={formData}
              onChange={(e) => {
                setFormData(e);
                template.onChange(
                  JSON.stringify(
                    e.map((item) => omit(item, ["id"])),
                    null,
                    2
                  )
                );
              }}
              onTabClick={(e) => setInputType(e)}
              onParse={handleParse}
              onValidate={handleValidation}
              isParsing={parseMutation.isLoading}
              onInitialize={handleInitialize}
              fieldsData={fieldsData}
              fieldsError={fieldsError}
              isFieldsError={isFieldsError}
              isFieldsLoading={isFieldsLoading}
            />
            <Editor2
              {...template}
              show={inputType === "raw"}
              onTabClick={(e) => {
                setInputType(e);
                if (e === "form") {
                  try {
                    setFormData(
                      JSON.parse(template.value).map((item, index) => ({
                        ...item,
                        id: index,
                      }))
                    );
                  } catch (error) {}
                }
              }}
              onParse={handleParse}
              onValidate={handleValidation}
              onSave={handleSave}
              onInitialize={handleInitialize}
              isParsing={parseMutation.isLoading}
            />
          </SideSection>
          <TableSection>
            <TabTitle>PARSE RESPONSE</TabTitle>
            <Editor {...response} />
          </TableSection>
        </MainContent>
        <Alert size="small" title="Validation" {...validationModal}>
          <div>{isJSON(template.value) ? "Success" : "Parse Error"}</div>
          <div className="d-flex">
            <Button
              size="small"
              className="mt-2 ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={() => validationModal.onClose()}
            >
              OK
            </Button>
          </div>
        </Alert>
      </MainLayout>
    </SkeletonTheme>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const Header = styled.div`
  height: 72px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const TabTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  text-decoration: underline;
  margin-bottom: 8px;
  padding: 0 0 0 10px;
`;

const ToolbarContainer = styled.div`
  height: 72px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  padding: 0 24px;
`;
