import {
  SET_LOADING,
  SET_ERROR,
  GET_SCHEDULERS,
  INIT_SCHEDULERS,
  GET_SCHEDULER_ID,
  GET_RETAILERS,
  GET_MORE_SCHEDULERS,
  SET_REFETCHING,
  SET_SCHEDULERS_SEARCH,
  SET_SCHEDULERS_FILTER,
  DELETE_SCHEDULER_ID,
} from "../types";
import { uniqBy } from "lodash";

export default function SchedulerReducer(state, action) {
  const { payload } = action;
  let schedulers = [];
  switch (action.type) {
    case INIT_SCHEDULERS:
      return {
        schedulers: [],
        retailers: [],
        isLoading: false,
        isRefetching: false,
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        currentPage: 0,
      };
    case GET_SCHEDULERS:
      schedulers = payload.results;
      return {
        ...state,
        schedulers,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
        retailers: uniqBy(
          schedulers.map((item) => ({
            retailer_id: item.retailer_id,
            name: item.retailer_name,
          })),
          "retailer_id"
        ),
      };
    case DELETE_SCHEDULER_ID:
      return {
        ...state,
        schedulers: state.schedulers.filter((a) => a.schedule_id !== payload),
        resultCount: state.resultCount - 1,
        totalPages: state.totalPages - 1,
      };
    case GET_MORE_SCHEDULERS:
      schedulers = [...state.schedulers, ...payload.results];
      return {
        ...state,
        schedulers,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
        retailers: uniqBy(
          schedulers.map((item) => ({
            retailer_id: item.retailer_id,
            name: item.retailer_name,
          })),
          "retailer_id"
        ),
      };
    case GET_RETAILERS:
      return {
        ...state,
        retailers: payload,
        isLoading: false,
      };
    case GET_SCHEDULER_ID:
      return {
        ...state,
        scheduler: payload,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_SCHEDULERS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_SCHEDULERS_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
