import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop } from "components";
import { useParams } from "@reach/router";
import { useSchedulerContext } from "contexts";
import {
  TableHeader,
  FilterSection,
  TableContent,
  ScheduleInfo,
  SearchBar,
} from "./components";

export const TaskPage = () => {
  const params = useParams();
  const { getSchedulerById, isLoading } = useSchedulerContext();
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);

  const init = async () => {
    getSchedulerById(params.id);
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (params) => {};

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
  };

  return (
    <MainLayout activeTab="SCHEDULERS">
      <Header>Schedulers - Nightly Walmart Toothpaste</Header>
      <ScheduleInfo />
      <SearchBar isLoaded={!isLoading} onSearch={handleSearch} />
      <MainContent>
        <SideSection>
          <SideTop count={100000} placeholder="tasks" isLoading={isLoading} />
          <FilterSection isLoading={isLoading} />
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent
            data={[]}
            isLoading={isLoading}
            sortCol={sortCol}
            sortType={sortType}
          />
        </TableSection>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 20px;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;
