import {
  SET_LOADING,
  SET_ERROR,
  INIT_PILOT_SETS,
  GET_PILOT_SETS,
  GET_MORE_PILOT_SETS,
  SET_REFETCHING,
  SET_PILOT_SETS_SEARCH,
  SET_PILOT_SETS_FILTER,
  DELETE_PILOT_SET,
} from "../types";

export default function PilotSetReducer(state, action) {
  const { payload } = action;
  let pilots = [];
  switch (action.type) {
    case INIT_PILOT_SETS:
      return {
        query: "",
        status: [],
        country: [],
        lang: [],
        currency: [],
        pilots: [],
        scheduler: {},
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        currentPage: 1,
        isLoading: false,
        isRefetching: false,
        creationDateFrom: "",
        creationDateTo: "",
        filterCountries: [],
        filterLanguages: [],
        filterCurrencies: [],
      };
    case GET_PILOT_SETS:
      pilots = payload.results;
      return {
        ...state,
        pilots,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };

    case GET_MORE_PILOT_SETS:
      pilots = [...state.pilots, ...payload.results];
      return {
        ...state,
        pilots,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_PILOT_SETS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_PILOT_SETS_FILTER:
      return {
        ...state,
        ...payload,
      };

    case DELETE_PILOT_SET:
      pilots = state.pilots.filter((a) => a.pilot_id !== payload);
      return {
        ...state,
        pilots,
        isLoading: false,
        isRefetching: false,
        totalCount: state.totalCount - 1,
        resultCount: state.resultCount - 1,
      };
    default:
      return state;
  }
}
