import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  Button,
  Input2,
  Editor,
  Alert,
  AutoComplete,
  DropDown2,
} from "components";
import { useParams, navigate } from "@reach/router";
import {
  testScrapeTemplateApi,
  searchRetailersApi,
  searchScrapeTypesApi,
  searchMatchFiltersApi,
  publishTemplateApi,
  getScrapeTemplateById,
  createScrapeTemplateApi,
  deleteScrapeTemplateById,
  updateScrapeTemplateName,
} from "api";
import { useMutation, useQuery } from "react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useInput, useModal } from "hooks";

export const ScrappingPage = () => {
  const params = useParams();
  const validationModal = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const parseMutation = useMutation((data) => testScrapeTemplateApi(data));
  const publishMutation = useMutation((data) => publishTemplateApi(data));
  const createMutation = useMutation((data) => createScrapeTemplateApi(data));
  const archiveScraperMutation = useMutation((data) =>
    deleteScrapeTemplateById(data)
  );
  const updateScrapeTemplateNameMutation = useMutation((data) =>
    updateScrapeTemplateName(data)
  );

  const statusValue = useInput("");
  const url = useInput("");
  const template = useInput("");
  const variables = useInput("");
  const retailer = useInput("");
  const scrapeType = useInput("");
  const response = useInput("");
  const name = useInput("");
  const matchFilter = useInput("");
  const [retailerName, setRetailerName] = useState("");
  const [scrapeTypeName, setScrapeTypeName] = useState("");
  const timeout = useInput("");

  const { data: matchFilterData } = useQuery(
    ["parse-template-match-filter", retailer.value, scrapeType.value],
    () =>
      searchMatchFiltersApi({
        retailer: retailer.value,
        scrape_type: scrapeType.value,
      }),
    {
      keepPreviousData: true,
      enabled: retailer.value > 0 && scrapeType.value > 0,
    }
  );

  const init = async () => {
    setIsLoading(true);
    let res = {};

    res = await getScrapeTemplateById(params.id);

    template.onChange(res?.contents || "");
    statusValue.setValue(res?.status);
    console.log("Status", statusValue);
    name.setValue(res?.name);
    setRetailerName(res?.retailer_name);
    setScrapeTypeName(res?.scrape_type_name);
    setIsLoading(false);
    retailer.setValue(res?.retailer_id);
    scrapeType.setValue(res?.scrape_type_id);
    variables.setValue(JSON.stringify(res?.variables, null, 2));
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const onChangeNameSave = async () => {
    var timeoutID;
    if (name.value !== "" && params && params.id) {
      console.log("Trigger", name.value, params.id);

      if (timeout.value) {
        console.log("timeout", timeout.value);
        if (timeout.value !== "init") {
          window.clearTimeout(timeout.value);
        }

        window.clearTimeout(timeout.value);
        timeoutID = window.setTimeout(async () => {
          const resp = await updateScrapeTemplateNameMutation.mutateAsync({
            name: name.value,
            scrape_template_id: params.id,
          });
          console.log("Name Saved", resp);
        }, 3000);
        timeout.setValue(timeoutID);
      } else {
        timeout.setValue("init");
      }
    }
  };

  useEffect(() => {
    onChangeNameSave();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name.value]);

  const handleParse = async () => {
    const resp = await parseMutation.mutateAsync({
      url: url.value,
      scrape_type_id: scrapeType.value,
      template: template.value,
      variables: JSON.parse(variables.value),
    });
    response.setValue(resp);
  };

  const handlePublish = async () => {
    await publishMutation.mutateAsync({
      match_filter_id: matchFilter.value,
      scrape_template_id: params.id,
    });
  };
  const handleValidation = () => {
    validationModal.openModal();
  };

  const handleSave = async () => {
    const resp = await createMutation.mutateAsync({
      retailer_id: retailer.value,
      scrape_type_id: scrapeType.value,
      name: name.value,
      version: 1,
      status: "NEW",
      scrape_variables: variables.value,
      scrape_template: template.value,
      user_id: 1,
      scrape_template_id: params.id,
    });
    console.log("save resp", resp);
    if (resp?.scrape_template_id && resp?.scrape_template_id !== params.id) {
      navigate(`/scrape_template/${resp?.scrape_template_id}`);
    }
  };

  const handleArchive = async () => {
    await archiveScraperMutation.mutateAsync(params.id);
    navigate(`/scrape_templates`);
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout activeTab="SCRAPE TEMPLATES">
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <Input2
                label="Template Name"
                className="col-6 my-auto"
                {...name}
              />

              <AutoComplete
                label="Retailer"
                className="col-3"
                {...retailer}
                idField="retailer_id"
                func={searchRetailersApi}
                displayValue={retailerName}
              />
              <AutoComplete
                label="Scrape Type"
                className="flex-1"
                {...scrapeType}
                idField="scrape_type_id"
                func={searchScrapeTypesApi}
                displayValue={scrapeTypeName}
              />

              {statusValue?.value !== "ARCHIVE" ? (
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-3 my-auto"
                  onClick={handleArchive}
                >
                  Archive
                </Button>
              ) : (
                ""
              )}
            </div>
          )}
        </Header>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <ToolbarContainer className="d-flex">
              <div className="d-flex my-auto">
                <Skeleton height={32} width={800} />
              </div>
            </ToolbarContainer>
          </SkeletonTheme>
        ) : (
          <ToolbarContainer>
            <div className="row w-100">
              <Input2 label="URL Match" className="col-6 my-auto" {...url} />
              <DropDown2
                label="Match Filter"
                className="col-3 my-auto"
                {...matchFilter}
                data={matchFilterData || []}
              />

              <Input2
                label="Status"
                className="flex-1 my-auto"
                {...statusValue}
              />
              {statusValue?.value !== "ARCHIVE" ? (
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-3 mt-auto mb-3"
                  onClick={handlePublish}
                  disabled={!matchFilter.value}
                  isLoading={publishMutation.isLoading}
                >
                  Publish
                </Button>
              ) : (
                ""
              )}
            </div>
          </ToolbarContainer>
        )}
        <MainContent>
          <SideSection>
            <div className="d-flex mb-2">
              <JsonTitle className="my-auto">DriverExtras Override</JsonTitle>
              <Button
                size="small"
                width="140px"
                className="ml-auto"
                buttonTheme="dark"
                onClick={handleParse}
                isLoading={parseMutation.isLoading}
                disabled={!url.value}
              >
                Parse
              </Button>
              <Button
                size="small"
                className="ml-2"
                width="120px"
                buttonTheme="dark"
                onClick={handleValidation}
                disabled={!template.value}
              >
                Validate
              </Button>
              <Button
                size="small"
                className="ml-2"
                width="120px"
                buttonTheme="dark"
                onClick={handleSave}
                isLoading={createMutation.isLoading}
              >
                Save
              </Button>
            </div>
            <Editor {...template} />
            <div className="d-flex my-2">
              <JsonTitle className="my-auto">Scrape Variables</JsonTitle>
            </div>
            <Editor {...variables} />
          </SideSection>
          <TableSection>
            <JsonTitle>Output</JsonTitle>
            <Editor {...response} />
          </TableSection>
        </MainContent>
        <Alert size="small" title="Validation" {...validationModal}>
          <div>{true ? "Success" : "Error"}</div>
          <div className="d-flex">
            <Button
              size="small"
              className="mt-2 ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={() => validationModal.onClose()}
            >
              OK
            </Button>
          </div>
        </Alert>
      </MainLayout>
    </SkeletonTheme>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const Header = styled.div`
  height: 72px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const JsonTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  margin-bottom: 8px;
  padding: 0 0 0 10px;
`;

const ToolbarContainer = styled.div`
  height: 72px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  padding: 0 24px;
`;
