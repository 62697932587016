import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import { useClickOutside } from "react-click-outside-hook";
import { FieldsModal } from "./fieldsModal";

export const SimpleFormAdd = ({
  className,
  onAdd = () => {},
  formData = [],
  fieldsData = [],
  fieldsError,
  isFieldsError,
  isFieldsLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ref, hasClickedOutside] = useClickOutside();

  const handleExpand = () => {
    setIsModalOpen((state) => !state);
  };
  useEffect(() => {
    if (hasClickedOutside) {
      setIsModalOpen(false);
    }
    return () => {};
  }, [hasClickedOutside]);

  return (
    <Container className={className} ref={ref}>
      <Button onClick={handleExpand}>Add Fields</Button>
      <DropDown onClick={handleExpand}>
        <FaCaretDown color="white" />
      </DropDown>
      <FieldsModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={onAdd}
        formData={formData}
        data={fieldsData}
        error={fieldsError}
        isError={isFieldsError}
        isLoading={isFieldsLoading}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: auto;
`;

const Button = styled.div`
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  background: ${(props) => props.theme.palette.items};
  cursor: pointer;
  transition: all 300ms ease;
  padding: 0 16px;
  &:hover {
    opacity: 0.7;
  }
`;

const DropDown = styled.div`
  height: 30px;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  background: ${(props) => props.theme.palette.items};
  cursor: pointer;
  transition: all 300ms ease;
  padding: 0 4px;
  margin-left: 2px;
  &:hover {
    opacity: 0.7;
  }
`;
