/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import { COUNT_PER_PAGE } from "config";
import PilotSetReducer from "./PilotSetReducer";
import {
  SET_LOADING,
  INIT_PILOT_SETS,
  GET_PILOT_SETS,
  SET_ERROR,
  GET_MORE_PILOT_SETS,
  SET_PILOT_SETS_SEARCH,
  SET_PILOT_SETS_FILTER,
  SET_REFETCHING,
  DELETE_PILOT_SET,
} from "../types";
import { getPilotSetsApi } from "api";
const PilotSetContext = createContext();
export const usePilotSetContext = () => useContext(PilotSetContext);
const STORAGE_KEY = "armitage-pilot-state";

export const PilotSetProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    country: [],
    lang: [],
    currency: [],
    pilots: [],
    scheduler: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    filterCountries: [],
    filterLanguages: [],
    filterCurrencies: [],
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(PilotSetReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getPilotSets = async () => {
    setPilotSetsLoading();
    const res = await getPilotSetsApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
    });
    dispatch({
      type: res.success ? GET_PILOT_SETS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setRetailersRefetching();
    const res = await getPilotSetsApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_PILOT_SETS : SET_ERROR,
      payload: res.payload,
    });
  };
  const refetchPilotSets = async () => {
    const res = await getPilotSetsApi();
    dispatch({
      type: res.success ? GET_PILOT_SETS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initPilotSets = async () => {
    dispatch({
      type: INIT_PILOT_SETS,
    });
  };

  // Set Loading
  const setPilotSetsLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });
  // Set Refetching
  const setRetailersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_PILOT_SETS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_PILOT_SETS_FILTER, payload: params });
  };

  const deleteById = (params) => {
    dispatch({ type: DELETE_PILOT_SET, payload: params });
  };

  const { children } = props;

  return (
    <PilotSetContext.Provider
      value={{
        ...state,
        initPilotSets,
        setPilotSetsLoading,
        getPilotSets,
        refetchPilotSets,
        loadMore,
        setQuery,
        setFilter,
        deleteById,
      }}
    >
      {children}
    </PilotSetContext.Provider>
  );
};
