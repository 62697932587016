import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSchedulerContext } from "contexts";
import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const statuses = [
  { label: "Enabled", name: "Enabled" },
  { label: "Disabled", name: "Disabled" },
  { label: "Paused", name: "Paused" },
  { label: "Errored", name: "Errored" },
];

const types = [
  { label: "Newlinks", name: "Newlinks" },
  { label: "Variants", name: "Variants" },
  { label: "Sitemap", name: "Sitemap" },
];

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  const {
    status,
    requestType,
    setFilter,
    creationDateFrom,
    creationDateTo,
    lastRunDateFrom,
    lastRunDateTo,
    retailers,
    retailerFilter,
  } = useSchedulerContext();

  const handleFilterStatus = (params) => {
    if (status && status.includes(params)) {
      setFilter({ status: status.filter((a) => a !== params) });
    } else {
      setFilter({ status: [...(status || []), params] });
    }
  };
  const handleFilterType = (params) => {
    if (requestType && requestType.includes(params)) {
      setFilter({ requestType: requestType.filter((a) => a !== params) });
    } else {
      setFilter({ requestType: [...(requestType || []), params] });
    }
  };
  const handleFilterRetailer = (params) => {
    if (retailerFilter && retailerFilter.includes(params)) {
      setFilter({ retailerFilter: retailerFilter.filter((a) => a !== params) });
    } else {
      setFilter({ retailerFilter: [...(retailerFilter || []), params] });
    }
  };
  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>
          <Accordion
            title="Status"
            desc=""
            className="mb-3"
            open={status && status?.length > 0}
          >
            {statuses.map(({ label, name }) => (
              <CheckBox
                key={name}
                value={(status || []).includes(name)}
                onChange={() => handleFilterStatus(name)}
              >
                {label}
              </CheckBox>
            ))}
          </Accordion>

          <Accordion
            title="Type"
            className="mb-3"
            open={requestType && requestType?.length > 0}
          >
            {types.map(({ label, name }) => (
              <CheckBox
                key={name}
                value={(requestType || []).includes(name)}
                onChange={() => handleFilterType(name)}
              >
                {label}
              </CheckBox>
            ))}
          </Accordion>
          <Accordion
            title="Retailers"
            className="mb-3"
            open={retailerFilter && retailerFilter?.length > 0}
          >
            <div className="py-3">
              {(retailers || []).map(({ retailer_id, name }) => (
                <CheckBox
                  key={retailer_id}
                  value={(retailerFilter || []).includes(retailer_id)}
                  onChange={() => handleFilterRetailer(retailer_id)}
                >
                  {name}
                </CheckBox>
              ))}
            </div>
          </Accordion>
          <Accordion
            title="Created Date"
            className="mb-3"
            open={creationDateFrom || creationDateTo}
          >
            <div className="py-3">
              <DateRange
                startDate={creationDateFrom}
                endDate={creationDateTo}
                onChange={(dates) => {
                  setFilter({
                    creationDateFrom: dates[0],
                    creationDateTo: dates[1],
                  });
                }}
              />
            </div>
          </Accordion>
          <Accordion
            title="Last Run Date"
            className="mb-3"
            open={lastRunDateFrom || lastRunDateTo}
          >
            <div className="py-3">
              <DateRange
                startDate={lastRunDateFrom}
                endDate={lastRunDateTo}
                onChange={(dates) => {
                  setFilter({
                    lastRunDateFrom: dates[0],
                    lastRunDateTo: dates[1],
                  });
                }}
              />
            </div>
          </Accordion>
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
