import React from "react";
import styled from "styled-components";

const data = ["VIEWER", "SCREENSHOT", "RAW CODE", "MAPPED DATA", "COMPARE"];

export const Type = () => {
  return (
    <Container>
      {data.map((item) => (
        <Item key={item}>{item} </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  padding: 8px 24px;
`;

const Item = styled.div`
  text-decoration: underline;
  color: ${(props) =>
    props.active ? props.theme.palette.primary : props.theme.palette.secondary};
  margin-right: 24px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
