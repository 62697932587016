import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useMutation } from "react-query";
import { deleteRetailerApi } from "api";
import { useRetailerContext } from "contexts";
import { format } from "date-fns";
import { CreateRetailerModal } from "modals";
import { useModal } from "hooks";

export const TableRow = ({ isLoading, data }) => {
  const deleteMutation = useMutation((data) => deleteRetailerApi(data));
  const { refetchRetailers } = useRetailerContext();
  const editModal = useModal();
  const handleDelete = async () => {
    await deleteMutation.mutateAsync(data?.retailer_id);
    refetchRetailers();
  };

  const handleEdit = async () => {
    editModal.openModal();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data?.status}>
      <div className="inner">
        {/* <RowItem></RowItem> */}
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.status}</RowItem>
        <RowItem>{data?.country}</RowItem>
        <RowItem>{data?.lang}</RowItem>
        <RowItem>{data?.currency}</RowItem>
        <RowItem></RowItem>
        <RowItem>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem className="d-flex">
          <span>
            {data?.archive_time
              ? format(new Date(data?.archive_time), "MM/dd/yyyy")
              : ""}
          </span>
          <div className="ml-auto d-flex mr-4">
            <span className="my-auto cursor-pointer" onClick={handleEdit}>
              <FaEdit />
            </span>
            <span
              className="ml-3 my-auto cursor-pointer"
              onClick={handleDelete}
            >
              <FaTrashAlt />
            </span>
          </div>
        </RowItem>
      </div>
      {data?.status === "Errored" && (
        <Error>
          Error: Tasks failed, please review task errors <a href="/#">here</a>.
        </Error>
      )}
      <CreateRetailerModal data={data} {...editModal} />
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "ACTIVE"
        ? props.theme.palette.success
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 66px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 120px;
    }
    & > div:nth-child(3) {
      width: 120px;
    }
    & > div:nth-child(4) {
      width: 120px;
    }
    & > div:nth-child(5) {
      width: 120px;
    }
    & > div:nth-child(6) {
      width: 120px;
    }
    & > div:nth-child(7) {
      width: 200px;
    }
    & > div:nth-child(8) {
      width: 200px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

const Error = styled.div`
  height: 40px;
  line-height: 40px;
  background: ${(props) => props.theme.palette.error};
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  padding-left: 40px;
  a {
    color: white;
    text-decoration: underline;
  }
`;
