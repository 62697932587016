/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, Button, Input2 } from "components";
import { Formik, Form } from "formik";
import { useInput } from "hooks";
import * as Yup from "yup";
import { useTransformsContext } from "contexts";
import { useMutation } from "react-query";
import { createTransformBlockApi } from "api";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const CreateTransformBlockModal = ({
  open,
  onClose,
  onCreate = () => {},
  data = {},
}) => {
  const name = useInput("");
  const { refetchTransforms } = useTransformsContext();

  const createMutation = useMutation((data) => createTransformBlockApi(data));

  useEffect(() => {
    if (open) {
      name.setValue(data?.name || "");
    }
    return () => {};
  }, [open]);

  const handleCreate = async () => {
    await createMutation.mutateAsync({
      name: name.value,
    });

    refetchTransforms();
    onClose();
  };

  return (
    <Modal
      open={open}
      size="large"
      onClose={onClose}
      title={"Create a New Transform Block"}
    >
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {}}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input2
              label="Block Name"
              name="name"
              placeholder="Enter Transform Block Name"
              {...name}
            />

            <div className="d-flex mt-4">
              <Button
                size="normal"
                buttonTheme="dark"
                width="fit-content"
                className="ml-auto"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={() => onClose()}
              >
                {"Cancel"}
              </Button>
              <Button
                size="normal"
                buttonTheme="primary"
                width="fit-content"
                className="ml-2"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={handleCreate}
                disabled={!name.value}
              >
                {"Create Block"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
