import {
  SET_LOADING,
  SET_ERROR,
  GET_JOBS,
  INIT_JOBS,
  GET_MORE_JOBS,
  SET_REFETCHING,
  SET_JOBS_SEARCH,
  SET_JOBS_FILTER,
} from "./../types";

export default function JobReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_JOBS:
      return {
        currentPage: 1,
        jobs: [],
        isLoading: false,
        isRefetching: false,
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: payload.results,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };
    case GET_MORE_JOBS:
      return {
        ...state,
        jobs: [...state.jobs, ...payload.results],
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_JOBS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_JOBS_FILTER:
      return {
        ...state,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
