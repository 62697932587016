/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, DropDown2, Button, Input2, AutoComplete } from "components";
import { Formik, Form } from "formik";
import { useInput } from "hooks";
import * as Yup from "yup";
import { FaTrashAlt } from "react-icons/fa";
import { useSchedulerContext } from "contexts";
import { useMutation } from "react-query";
import { createScheduler, searchRetailersApi } from "api";
import { validURL } from "utils";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const CreateSchedulerModal = ({
  open,
  onClose,
  onCreate = () => {},
}) => {
  const [urls, setUrls] = useState([]);
  const name = useInput("");
  const addUrl = useInput("");
  const requestType = useInput("");
  const retailer = useInput("");
  const [domain, setDomain] = useState("");
  const { refetchSchedulers } = useSchedulerContext();

  const createMutation = useMutation((data) => createScheduler(data));

  useEffect(() => {
    if (open) {
      setUrls([]);
      name.setValue("");
      addUrl.setValue("");
      requestType.setValue("");
      retailer.setValue("");
    }
    return () => {};
  }, [open]);

  const handleAddUrl = () => {
    setUrls((urls) => [addUrl.value, ...urls]);
    addUrl.setValue("");
  };

  const handleRemoveUrl = (index) => {
    let temp = [...urls];
    temp.splice(index, 1);
    setUrls(temp);
  };

  const handleCreate = async () => {
    await createMutation.mutateAsync({
      name: name.value,
      payload: {
        request: {
          retailer: domain,
          type: requestType.value,
          options: {
            urls,
          },
        },
      },
    });
    refetchSchedulers();
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose} title={"Create Scheduler"}>
      <Formik
        initialValues={{
          password: "",
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {}}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-6">
                <Input2 label="Scheduler Name" name="username" {...name} />

                <AutoComplete
                  label="Retailer"
                  className="mt-3"
                  {...retailer}
                  idField="retailer_id"
                  func={searchRetailersApi}
                  onSelectValue={setDomain}
                />

                <DropDown2
                  className="mt-3"
                  label="Request Type"
                  data={[
                    {
                      id: "newlink",
                      title: "Newlink",
                    },
                    {
                      id: "variant",
                      title: "Variants",
                    },
                    {
                      id: "sitemap",
                      title: "Sitemap",
                    },
                    {
                      id: "crawl",
                      title: "Crawl",
                    },
                  ]}
                  {...requestType}
                />
                {/* <DropDown2 className="mt-3" label="Scrape Template" />
                <Input2 className="mt-3" label="Start Date" />
                <DropDown2 className="mt-3" label="Frequency" /> */}
              </div>
              <div className="col-6">
                <div className="d-flex w-100">
                  <Input2
                    label="Add A URL"
                    className="flex-1"
                    name="username"
                    {...addUrl}
                  />
                  <Button
                    size="small"
                    width="120px"
                    className="mt-3 ml-2"
                    onClick={() => handleAddUrl()}
                    disabled={!validURL(addUrl.value)}
                  >
                    ADD URL
                  </Button>
                </div>
                <div className="d-flex mt-4">
                  <Label className="mt-auto">URL List</Label>
                  <Count className="mt-auto ml-2">{urls.length} URLs</Count>

                  <Button
                    className="ml-auto"
                    size="small"
                    width="180px"
                    buttonTheme="dark"
                  >
                    Import URL List
                  </Button>
                </div>
                <UrlList className="mt-3">
                  {urls.map((url, index) => (
                    <Url key={`${index}key`}>
                      <div>{url}</div>
                      <div
                        className="ml-auto my-auto cursor-pointer"
                        onClick={() => handleRemoveUrl(index)}
                      >
                        <FaTrashAlt color="white" />
                      </div>
                    </Url>
                  ))}
                </UrlList>
              </div>
            </div>
            <div className="d-flex mt-4">
              <Button
                size="normal"
                buttonTheme="primary"
                width="fit-content"
                className="ml-auto"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={handleCreate}
                disabled={!(name.value && retailer.value && requestType.value)}
              >
                Create Schedule
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const UrlList = styled.div`
  height: 300px;
  padding-right: 0px;
  flex: 1;
  margin-right: 4px;
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.palette.gray};
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
const Url = styled.div`
  background: ${(props) => props.theme.palette.items};
  height: 44px;
  margin: 2px 0;
  padding: 12px;
  font-size: 14px;
  display: flex;
`;

const Label = styled.div`
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.palette.secondary};
  display: flex;
  margin-bottom: 0;
`;

const Count = styled.div`
  font-size: ${(props) => props.theme.font.size.m};
  color: ${(props) => props.theme.palette.white};
  display: flex;
  margin-bottom: 0;
`;
