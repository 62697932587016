import { API } from "utils";

export const peekApi = async (params) => {
  try {
    const response = await API().post(`peek/${params}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const crawlApi = async (params) => {
  try {
    const formData = new FormData();
    formData.append("urls", params.urls);
    const response = await API().post(`crawl`, formData);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
