import React from "react";
import styled from "styled-components";

const Back = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow:auto;
`;

export const SchemaLayout = ({ children }) => {
  return (
    <Back>
      {children}
    </Back>
  );
};
