/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import { COUNT_PER_PAGE } from "config";
import MatchFilterReducer from "./MatchFilterReducer";
import {
  SET_LOADING,
  INIT_MATCH_FILTERS,
  GET_MATCH_FILTERS,
  SET_ERROR,
  GET_MORE_MATCH_FILTERS,
  SET_MATCH_FILTERS_SEARCH,
  SET_MATCH_FILTERS_FILTER,
  SET_REFETCHING,
  DELETE_MATCH_FILTER,
} from "../types";
import { getMatchFiltersApi } from "api";
const MatchFilterContext = createContext();
export const useMatchFilterContext = () => useContext(MatchFilterContext);
const STORAGE_KEY = "armitage-match-filter-state";

export const MatchFilterProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    country: [],
    lang: [],
    currency: [],
    matchFilters: [],
    scheduler: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    filterCountries: [],
    filterLanguages: [],
    filterCurrencies: [],
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(MatchFilterReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getMatchFilters = async () => {
    // dispatch({
    //   type: INIT_MATCH_FILTERS,
    // });
    setMatchFiltersLoading();
    const res = await getMatchFiltersApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
    });
    dispatch({
      type: res.success ? GET_MATCH_FILTERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setRetailersRefetching();
    const res = await getMatchFiltersApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_MATCH_FILTERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const refetchMatchFilters = async () => {
    const res = await getMatchFiltersApi();
    dispatch({
      type: res.success ? GET_MATCH_FILTERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initMatchFilters = async () => {
    dispatch({
      type: INIT_MATCH_FILTERS,
    });
  };

  // Set Loading
  const setMatchFiltersLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });
  // Set Refetching
  const setRetailersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_MATCH_FILTERS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_MATCH_FILTERS_FILTER, payload: params });
  };

  const deleteById = (params) => {
    dispatch({ type: DELETE_MATCH_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <MatchFilterContext.Provider
      value={{
        ...state,
        initMatchFilters,
        setMatchFiltersLoading,
        getMatchFilters,
        refetchMatchFilters,
        loadMore,
        setQuery,
        setFilter,
        deleteById,
      }}
    >
      {children}
    </MatchFilterContext.Provider>
  );
};
