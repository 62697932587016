import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const statuses = [
  { label: "Healthy", name: "Completed" },
  { label: "Processing", name: "Processing" },
  { label: "Errored", name: "Errored" },
  { label: "Archived", name: "Archived" },
  { label: "Paused", name: "Paused" },
];

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  const handleFilter = (params) => {};

  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>
          <Accordion title="Status" desc="" className="mb-3" open={false}>
            {statuses.map(({ label, name }) => (
              <CheckBox key={name} onChange={() => handleFilter(name)}>
                {label}
              </CheckBox>
            ))}
          </Accordion>

          <Accordion title="URL" className="mb-3" open={false}>
            <div className="py-3"></div>
          </Accordion>
          <Accordion title="Type" className="mb-3" open={false}>
            <div className="py-3"></div>
          </Accordion>
          <Accordion title="Run Date" className="mb-3" open={false}>
            <div className="py-3">
              <DateRange className="pt-2" />
            </div>
          </Accordion>
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
