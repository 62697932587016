import React from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, Input2, Editor, AutoComplete, Alert } from "components";
import {
  createScrapeTemplateApi,
  searchRetailersApi,
  searchScrapeTypesApi,
} from "api";
import { useMutation } from "react-query";
import { useInput, useModal } from "hooks";
import { navigate } from "@reach/router";
import { isJSON } from "utils";

export const CreateScrapeTemplatePage = () => {
  const createMutation = useMutation((data) => createScrapeTemplateApi(data));

  const varaibleValidationModal = useModal();
  const contents = useInput("");
  const variables = useInput("");
  const retailer = useInput("");
  const scrapeType = useInput("");
  const name = useInput("");

  const handleCreate = async () => {
    const resp = await createMutation.mutateAsync({
      retailer_id: retailer.value,
      scrape_type_id: scrapeType.value,
      name: name.value,
      version: 1,
      status: "NEW",
      scrape_variables: variables.value || "{}",
      scrape_template: contents.value,
      user_id: 1,
    });

    if (resp?.scrape_template_id) {
      navigate(`/scrape_template/${resp?.scrape_template_id}`);
    }
  };

  const handldVaraibleValidation = () => {
    varaibleValidationModal.openModal();
  };

  const isValid = () => name.value && retailer.value && scrapeType.value;

  return (
    <MainLayout activeTab="TEMPLATES">
      <Header>
        <div className="d-flex row w-100">
          <Input2 label="Name" className="my-auto col-6" {...name} />
          <AutoComplete
            label="Retailer"
            className="col-3"
            {...retailer}
            idField="retailer_id"
            func={searchRetailersApi}
          />
          <AutoComplete
            label="Scrape Type"
            className="flex-1"
            {...scrapeType}
            idField="scrape_type_id"
            func={searchScrapeTypesApi}
          />
          <Button
            buttonTheme="dark"
            width="100px"
            size="small"
            className="ml-3 my-auto"
            onClick={handleCreate}
            isLoading={createMutation.isLoading}
            disabled={!isValid()}
          >
            Create
          </Button>
        </div>
      </Header>

      <MainContent>
        <SideSection>
          <div className="d-flex mb-2">
            <JsonTitle className="my-auto">DriverExtras Override</JsonTitle>
          </div>
          <Editor {...contents} />
        </SideSection>
        <TableSection>
          <div className="d-flex mb-2">
            <JsonTitle className="my-auto">Scrape Variables</JsonTitle>
            <Button
              size="small"
              className="ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={handldVaraibleValidation}
              disabled={!variables.value}
            >
              Validate
            </Button>
          </div>
          <Editor {...variables} />
        </TableSection>
      </MainContent>
      <Alert size="small" title="Validation" {...varaibleValidationModal}>
        <div>{isJSON(variables.value) ? "Success" : "Error"}</div>
        <div className="d-flex">
          <Button
            size="small"
            className="mt-2 ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => varaibleValidationModal.onClose()}
          >
            OK
          </Button>
        </div>
      </Alert>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const Header = styled.div`
  height: 72px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const JsonTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  margin-bottom: 8px;
  padding: 0 0 0 10px;
`;
