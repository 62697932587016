import { API } from "utils";

export const getTransformsApi = async (params) => {
  try {
    const response = await API().get("transform_blocks", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getTransformsByIdApi = async (id) => {
  try {
    const response = await API().get(`transform_block/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteTransformsByIdApi = async (id) => {
  try {
    const response = await API().delete(`transform_block/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createTransformBlockApi = async (req) => {
  const init = {
    user_id: 0,
    name: "",
    version: 0,
    status: "NEW",
    contents: "{}",
    variables: "{}",
  };
  try {
    const response = await API().post(`transform_block`, { ...init, ...req });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const transformBlockApi = async (req) => {
  const init = {
    parsed: "{}",
    contents: "{}",
    variables: "{}",
  };
  try {
    const response = await API().post(`transform_block_test`, {
      ...init,
      ...req,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const publishTransformApi = async (id) => {
  try {
    const response = await API().post(`publish_transform`, {
      transform_block_id: id,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
