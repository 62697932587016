/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useInput } from "hooks";

const TYPE_DATA = [
  { id: "xpath", title: "xpath" },
  { id: "css", title: "css" },
  { id: "json", title: "json" },
  { id: "regex", title: "regex" },
  { id: "copy", title: "copy" },
  { id: "extruct", title: "extruct" },
  { id: "text", title: "text" }
];

const EXPECT_DATA = [
  { id: "int", title: "int" },
  { id: "str", title: "str" },
  { id: "null", title: "null" },
];

export const SimpleForm = ({
  index,
  onUp = () => {},
  onDown = () => {},
  onClose = () => {},
  onChange = () => {},
  onRawClick = () => {},
  data,
  isLast = false,
  disableArrow = false,
}) => {
  const fieldName = useInput("");
  const type = useInput("");
  const expect = useInput("");
  const endpoint = useInput("");
  const path = useInput("");

  useEffect(() => {
    if (data?.field) {
      fieldName.setValue(data?.field);
      type.setValue(data?.type);
      expect.setValue(data?.expect);
      endpoint.setValue(data?.endpoint);
      path.setValue(data?.path);
    }
    return () => {};
  }, [data]);

  const handleChange = (e) => {
    onChange({ ...data, ...e });
  };

  return (
    <Container>
      <div className="d-flex">
        <Index>{index}</Index>
        <Arrow
          className="ml-3"
          disabled={index === 1 || disableArrow}
          onClick={() => {
            if (index > 1 && !disableArrow) onUp(index - 1);
          }}
        >
          <FaCaretUp
            size={30}
            color={index === 1 || disableArrow ? "gray" : "white"}
          />
        </Arrow>
        <Arrow
          className="ml-1"
          disabled={isLast || disableArrow}
          onClick={() => {
            if (!isLast && !disableArrow) onDown(index - 1);
          }}
        >
          <FaCaretDown
            size={30}
            color={isLast || disableArrow ? "gray" : "white"}
          />
        </Arrow>
        <Arrow className="ml-auto" onClick={() => onClose(index - 1)}>
          <AiOutlineClose color="white" />
        </Arrow>
      </div>
      {data?.section ? (
        <div className="mt-3 d-flex">
          <div>
            <Label>SECTION NAME</Label>
            <Value>{data?.section}</Value>
          </div>
          <div className="ml-2">
            <Label>NOTICE</Label>
            <Value>
              This field is not editable within the simple form. Click here to
              edit within
              <RawLink onClick={onRawClick}>Raw JSON</RawLink>
            </Value>
          </div>
        </div>
      ) : (
        <div className="mt-3 d-flex">
          <Input2
            label="FIELD NAME"
            value={fieldName.value}
            onChange={(field) => {
              fieldName.setValue(field);
              handleChange({ field });
            }}
          />
          <div style={{ width: 120 }}>
            <DropDown2
              label="TYPE"
              className="ml-3"
              data={TYPE_DATA}
              value={type.value}
              onChange={(e) => {
                type.setValue(e);
                handleChange({ type: e });
              }}
            />
          </div>
          <div style={{ width: 120 }}>
            <DropDown2
              label="EXPECT"
              className="ml-3"
              data={EXPECT_DATA}
              value={expect.value}
              onChange={(e) => {
                expect.setValue(e);
                handleChange({ expect: e });
              }}
            />
          </div>
          <Input2
            label="ENDPOINT"
            className="ml-3"
            value={endpoint.value}
            onChange={(e) => {
              endpoint.setValue(e);
              handleChange({ endpoint: e });
            }}
          />
          <Input2
            label="PATH"
            className="ml-3 flex-1"
            value={path.value}
            onChange={(e) => {
              path.setValue(e);
              handleChange({ path: e });
            }}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 4px;
  margin: 4px;
`;

const Index = styled.div`
  font-size: ${(props) => props.theme.font.size.s};
  color: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  min-width: 16px;
  text-align: center;
  line-height: 24px;
  height: 24px;
  margin-top: auto;
`;

const Arrow = styled.div`
  color: white;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;

const Label = styled.div`
  font-size: 12px;
  color: #707070;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
`;

const Value = styled.div`
  display: flex;
  color: white;
  transition: background-color 5000s linear 0s;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  padding: 4px;
  overflow-x: hidden;
  background-color: transparent;
  border: none;
  outline: none;
`;

const RawLink = styled.div`
  color: #006280;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  margin-bottom: 8px;
  padding: 0 0 0 10px px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  margin-left: 4px;
  &:hover {
    opacity: 0.7;
  }
`;
