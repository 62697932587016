import axios from "axios";
import { API } from "utils";
export const login = async (user) => {
  const formData = new FormData();

  formData.append("username", user.username);
  formData.append("password", user.password);

  try {
    const response = await API().post("login", formData);
    if (response.data.access_token) {
      const authHeader =
        response.data.token_type + " " + response.data.access_token;
      localStorage.setItem("user", authHeader);
      axios.defaults.headers.common["Authorization"] = authHeader;
      return "success";
    }
    return "error";
  } catch (e) {
    return e;
  }
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const register = async (user) => {
  try {
    const formData = new FormData();
    formData.append("username", user.username);
    formData.append("password", user.password);
    await API().post("register", formData);

    return "success";
  } catch (error) {
    return error;
  }
};
