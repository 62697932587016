/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import { COUNT_PER_PAGE } from "config";
import RetailerReducer from "./RetailerReducer";
import {
  SET_LOADING,
  INIT_RETAILERS,
  GET_RETAILERS,
  SET_ERROR,
  GET_MORE_RETAILERS,
  SET_RETAILERS_SEARCH,
  SET_RETAILERS_FILTER,
  SET_REFETCHING,
} from "../types";
import { getRetailersApi } from "api";
const RetailerContext = createContext();
console.log("RetailerContext", RetailerContext);
export const useRetailerContext = () => useContext(RetailerContext);
console.log("useRetailerContext", useRetailerContext);
const STORAGE_KEY = "armitage-retailer-state";

export const RetailerProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    country: [],
    lang: [],
    currency: [],
    retailers: [],
    scheduler: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    archiveDateFrom: "",
    archiveDateTo: "",
    filterCountries: [],
    filterLanguages: [],
    filterCurrencies: [],
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(RetailerReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getRetailers = async () => {
    // dispatch({
    //   type: INIT_RETAILERS,
    // });
    setRetailersLoading();
    const res = await getRetailersApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      archive_time_from: state?.archiveDateFrom,
      archive_time_to: state?.archiveDateTo,
    });
    dispatch({
      type: res.success ? GET_RETAILERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setRetailersRefetching();
    const res = await getRetailersApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      lang: (state?.lang || []).join(","),
      country: (state?.country || []).join(","),
      currency: (state?.currency || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      archive_time_from: state?.archiveDateFrom,
      archive_time_to: state?.archiveDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_RETAILERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const refetchRetailers = async () => {
    const res = await getRetailersApi();
    dispatch({
      type: res.success ? GET_RETAILERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initRetailers = async () => {
    dispatch({
      type: INIT_RETAILERS,
    });
  };

  // Set Loading
  const setRetailersLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });
  // Set Refetching
  const setRetailersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_RETAILERS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_RETAILERS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <RetailerContext.Provider
      value={{
        ...state,
        initRetailers,
        setRetailersLoading,
        getRetailers,
        refetchRetailers,
        loadMore,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </RetailerContext.Provider>
  );
};
