/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, AutoComplete, Button, Input2 } from "components";
import { useInput } from "hooks";
import { usePilotSetContext } from "contexts";
import { useMutation } from "react-query";
import {
  createPilotSetsApi,
  searchScrapeTypesApi,
  searchRetailersApi,
  updatePilotSetsApi,
} from "api";

export const CreatePilotSetModal = ({
  open,
  onClose,
  onCreate = () => {},
  data,
}) => {
  const name = useInput("");
  const scrapeType = useInput("");
  const retailer = useInput("");
  const { refetchPilotSets } = usePilotSetContext();

  const createMutation = useMutation((data) => createPilotSetsApi(data));
  const updateMutation = useMutation((data) => updatePilotSetsApi(data));

  useEffect(() => {
    if (open) {
      name.setValue(data?.name || "");
      retailer.setValue(data?.retailer_id || "");
      scrapeType.setValue(data?.scrape_type_id || "");
    }
    return () => {};
  }, [open]);

  const handleCreate = async () => {
    if (data?.name) {
      await updateMutation.mutateAsync({
        id: data?.pilot_id,
        name: name.value,
        retailer_id: retailer.value,
        scrape_type_id: scrapeType.value,
      });
    } else {
      await createMutation.mutateAsync({
        name: name.value,
        retailer_id: retailer.value,
        scrape_type_id: scrapeType.value,
      });
    }
    refetchPilotSets();
    onClose();
  };

  const isFormValid = () => !(name.value && scrapeType.value && retailer.value);

  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      title={data?.name ? "Update Pilot Set" : "Create Pilot Set"}
    >
      <Input2 label="Name" name="username" {...name} />
      <AutoComplete
        label="Retailer"
        className="mt-3"
        {...retailer}
        idField="retailer_id"
        func={searchRetailersApi}
        displayValue={data?.retailer_name}
      />
      <AutoComplete
        label="Scrape Type"
        className="mt-3"
        {...scrapeType}
        idField="scrape_type_id"
        func={searchScrapeTypesApi}
        displayValue={data?.scrape_type_name}
      />

      <div className="d-flex mt-4">
        <Button
          size="normal"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          size="normal"
          buttonTheme="primary"
          width="fit-content"
          className="ml-3"
          type="button"
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          onClick={handleCreate}
          disabled={isFormValid()}
        >
          {data?.name ? "Update Pilot Set" : "Create Pilot Set"}
        </Button>
      </div>
    </Modal>
  );
};
