import { API } from "utils";

export const getRetailersApi = async (params) => {
  try {
    const response = await API().get("retailers", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const searchRetailersApi = async (search) => {
  try {
    const response = await API().get("retailers", {
      params: {
        search,
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createRetailerApi = async (data) => {
  try {
    const response = await API().post("retailer", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateRetailerApi = async (data) => {
  try {
    const response = await API().put(`retailer/${data?.id}`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteRetailerApi = async (id) => {
  try {
    const response = await API().delete(`retailer/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
