import React from "react";
import styled from "styled-components";
import { Button, SearchInput } from "components";
import { useJobContext } from "contexts";

export const SearchBar = ({ onSearch = () => {}, isLoading }) => {
  const { query, setQuery, totalCount } = useJobContext();

  return (
    <Container>
      <LeftPanel>
        <SearchContainer>
          <SearchInput
            placeholder={`Search ${totalCount} Jobs`}
            className="my-auto w-100"
            onEnter={() => onSearch(query)}
            disabled={isLoading}
            value={query}
            onChange={setQuery}
          />
        </SearchContainer>
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="my-auto ml-3"
          disabled={isLoading}
          onClick={() => onSearch(query)}
        >
          Search
        </Button>
      </LeftPanel>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  margin-bottom: 2px;
`;
const LeftPanel = styled.div`
  flex: 1;
  padding-left: 0px;
  display: flex;
`;
const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
