import {
  SET_LOADING,
  SET_ERROR,
  GET_TRANSFORMS,
  GET_MORE_TRANSFORMS,
  INIT_TRANSFORMS,
  GET_TRANSFORMS_ID,
  GET_TRANSFORMS_RETAILER,
  SET_REFETCHING,
  SET_TRANSFORMS_SEARCH,
  SET_TRANSFORMS_FILTER,
} from "../types";

export default function TransformsReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_TRANSFORMS:
      return {
        query: "",
        templates: [],
        template: {},
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        currentPage: 1,
        isLoading: false,
        isRefetching: false,
        creationDateFrom: "",
        creationDateTo: "",
        archivedDateFrom: "",
        archivedDateTo: "",
      };
    case GET_TRANSFORMS:
      return {
        ...state,
        templates: payload.results,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };
    case GET_MORE_TRANSFORMS:
      return {
        ...state,
        templates: [...state.templates, ...payload.results],
        isRefetching: false,
        isLoading: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case GET_TRANSFORMS_RETAILER:
      return {
        ...state,
        template: payload,
        isLoading: false,
      };
    case GET_TRANSFORMS_ID:
      return {
        ...state,
        template: payload,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_TRANSFORMS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_TRANSFORMS_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
