import { API } from "utils";

export const getTemplatesApi = async (search = "", page = 0) => {
  try {
    const response = await API().get("parse_templates");
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const publishTemplateApi = async (data) => {
  try {
    const response = await API().post("publish_template", data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getTemplateById = async (id) => {
  try {
    const response = await API().get(`parse_template/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getTemplateByRetailer = async (id) => {
  try {
    const response = await API().get(`parse_templates/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
