import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SideTop = ({ currentCount, totalCount, isLoading }) => {
  return (
    <Container>
      {isLoading ? (
        <div className="w-100">
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton height={24} className="w-100 d-flex" />
          </SkeletonTheme>
        </div>
      ) : (
        <div className="d-flex w-100">
          <div className="placeholder fit-content">
            {currentCount > 0 && (
              <>
                Showing <span className="count">{`1-${currentCount}`}</span> of{" "}
                <span className="count">{totalCount}</span>
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 50px; */
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.xm};
  padding: 12px 24px 6px 24px;
  .count {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.palette.white};
  }
  .placeholder {
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.palette.secondary};
    height: 32px;
  }
`;
