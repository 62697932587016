import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView, Button, RiseLoader } from "components";
import { useRetailerContext } from "contexts";
import { SearchBar } from "./components";
import { times } from "lodash";
import { TableHeader, FilterSection, TableRow } from "./components";
import { COUNT_PER_PAGE } from "config";

export const RetailersPage = () => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const {
    retailers,
    getRetailers,
    isLoading,
    isRefetching,
    resultCount,
    totalPages,
    currentPage,
    loadMore,
  } = useRetailerContext();

  const init = async () => {
    getRetailers();
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (params) => {
    getRetailers();
  };

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
  };

  return (
    <MainLayout activeTab="RETAILERS">
      <SearchBar isLoading={isLoading} onSearch={handleSearch} />
      <MainContent>
        <SideSection>
          <SideTop
            currentCount={
              currentPage * COUNT_PER_PAGE > resultCount
                ? resultCount
                : currentPage * COUNT_PER_PAGE
            }
            totalCount={resultCount}
            placeholder="schedules"
            isLoading={isLoading}
          />
          <FilterSection isLoading={isLoading} />
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent>
            {isLoading
              ? times(7, String).map((index) => (
                  <TableRow key={`${index}key`} isLoading />
                ))
              : retailers &&
                retailers.map((item, index) => (
                  <TableRow key={`${index}key`} data={item} />
                ))}
            {!isLoading && isRefetching ? (
              <div className="d-flex">
                <RiseLoader className="my-4 mx-auto" />
              </div>
            ) : !isLoading && currentPage < totalPages ? (
              <div className="my-4 d-flex justify-content-center">
                <Button size="small" width="120px" onClick={loadMore}>
                  Load More
                </Button>
              </div>
            ) : null}
          </TableContent>
        </TableSection>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;
