import { API } from "utils";

export const getScrapeTemplatesApi = async (params) => {
  try {
    const response = await API().get("scrape_templates", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createScrapeTemplateApi = async (data) => {
  try {
    const response = await API().post("scrape_template", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const searchScrapeTemplatesApi = async (search) => {
  try {
    const response = await API().get("scrape_templates", {
      params: {
        search,
        status: 'ACTIVE',
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getScrapeTemplateById = async (id) => {
  try {
    const response = await API().get(`scrape_template/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteScrapeTemplateById = async (id) => {
  try {
    const response = await API().delete(`scrape_template/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const testScrapeTemplateApi = async (data) => {
  try {
    const response = await API().post(`scrape_template_test_url`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateScrapeTemplateName = async (data) => {
  try {
    const response = await API().put(`scrape_template/${data.scrape_template_id}`, {"name":data.name});
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
