import React from "react";
import styled from "styled-components";
import { FaEdit } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "@reach/router";
import { format } from "date-fns";

const LinkStyle = {
  color: "white",
};

export const TableRow = ({ isLoading, status, data }) => {
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data?.status}>
      <div className="inner">
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.status}</RowItem>
        <RowItem>{data?.version}</RowItem>
        <RowItem>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem className="d-flex">
          <span>
            {data?.update_time
              ? format(new Date(data?.update_time), "MM/dd/yyyy")
              : ""}
          </span>
          <div className="ml-auto d-flex mr-4">
            <Link
              style={LinkStyle}
              className="my-auto cursor-pointer"
              to={`/transform/${data?.transform_block_id}`}
            >
              <FaEdit />
            </Link>
          </div>
        </RowItem>
      </div>
      {status === "error" && (
        <Error>
          Error: Tasks failed, please review task errors <a href="/#">here</a>.
        </Error>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "ACTIVE"
        ? props.theme.palette.success
        : props.status === "ARCHIVED"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 20px;
    height: 66px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 120px;
    }
    & > div:nth-child(3) {
      width: 120px;
    }
    & > div:nth-child(4) {
      width: 120px;
    }
    & > div:nth-child(5) {
      width: 300px;
    }
    & > div:nth-child(6) {
      width: 120px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

const Error = styled.div`
  height: 40px;
  line-height: 40px;
  background: ${(props) => props.theme.palette.error};
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  padding-left: 40px;
  a {
    color: white;
    text-decoration: underline;
  }
`;
