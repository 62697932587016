import {
  SET_LOADING,
  SET_ERROR,
  INIT_RETAILERS,
  GET_RETAILERS,
  GET_MORE_RETAILERS,
  SET_REFETCHING,
  SET_RETAILERS_SEARCH,
  SET_RETAILERS_FILTER,
} from "../types";
import { uniq } from "lodash";

export default function RetailerReducer(state, action) {
  const { payload } = action;
  let retailers = [];
  switch (action.type) {
    case INIT_RETAILERS:
      return {
        ...state,
        schedulers: [],
        isLoading: false,
      };
    case GET_RETAILERS:
      retailers = payload.results;
      return {
        ...state,
        retailers,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
        filterCurrencies: uniq(retailers.map((a) => a.currency)),
        filterLanguages: uniq(retailers.map((a) => a.lang)),
        filterCountries: uniq(retailers.map((a) => a.country)),
      };

    case GET_MORE_RETAILERS:
      retailers = [...state.retailers, ...payload.results];
      return {
        ...state,
        retailers,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
        filterCurrencies: uniq(retailers.map((a) => a.currency)),
        filterLanguages: uniq(retailers.map((a) => a.lang)),
        filterCountries: uniq(retailers.map((a) => a.country)),
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_RETAILERS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_RETAILERS_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
