/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Button,
  Input2,
  TextArea2,
  AutoComplete,
  CheckBox,
} from "components";
import { useInput } from "hooks";
import { useMatchFilterContext } from "contexts";
import { useMutation } from "react-query";
import {
  createMatchFilterApi,
  updateMatchFilterApi,
  searchRetailersApi,
  searchScrapeTypesApi,
  searchPilotSetsApi,
  searchParseTemplatesApi,
  searchScrapeTemplatesApi,
} from "api";

export const CreateMatchFilterModal = ({
  open,
  onClose,
  onCreate = () => {},
  data,
}) => {
  const name = useInput("");
  const description = useInput("");
  const scrapeType = useInput("");
  const retailer = useInput("");
  const pilot = useInput("");
  const parseTemplate = useInput("");
  const scrapeTemplate = useInput("");
  const url = useInput("");
  const matcher = useInput("");
  const urlRegex = useInput("");
  const profiles = useInput("");
  const discoveryAutoInsert = useInput(false);
  const { refetchMatchFilters } = useMatchFilterContext();

  const createMutation = useMutation((data) => createMatchFilterApi(data));
  const updateMutation = useMutation((data) => updateMatchFilterApi(data));

  useEffect(() => {
    if (open) {
      name.setValue(data?.name || "");
      description.setValue(data?.description || "");
      scrapeType.setValue(data?.scrape_type_id || "");
      retailer.setValue(data?.retailer_id || "");
      pilot.setValue(data?.pilot_template_id || "");
      parseTemplate.setValue(data?.parse_template_id || "");
      scrapeTemplate.setValue(data?.scrape_template_id || "");
      url.setValue(data?.url_path || "");
      matcher.setValue(data?.matcher || "");
      urlRegex.setValue(data?.url_regex || "");
      profiles.setValue(data?.profile_ids || "");
      discoveryAutoInsert.setValue(data?.discovery_auto_insert || false);
    }
    return () => {};
  }, [open]);

  const handleCreate = async () => {
    if (data?.name) {
      await updateMutation.mutateAsync({
        id: data?.match_filter_id,
        retailer_id: retailer.value,
        matcher: matcher.value,
        name: name.value,
        description: description.value,
        url_path: url.value,
        scrape_template_id:
          scrapeTemplate.value === "" ? null : scrapeTemplate.value,
        parse_template_id:
          parseTemplate.value === "" ? null : parseTemplate.value,
        pilot_template_id: pilot.value === "" ? null : pilot.value,
        scrape_type_id: scrapeType.value,
        url_regex: urlRegex.value,
        profile_ids: profiles.value,
        discovery_auto_insert: 
          discoveryAutoInsert.value === "" ? false : discoveryAutoInsert.value,
      });
    } else {
      await createMutation.mutateAsync({
        retailer_id: retailer.value,
        matcher: matcher.value,
        name: name.value,
        description: description.value,
        url_path: url.value,
        scrape_template_id:
          scrapeTemplate.value === "" ? null : scrapeTemplate.value,
        parse_template_id:
          parseTemplate.value === "" ? null : parseTemplate.value,
        pilot_template_id: pilot.value === "" ? null : pilot.value,
        scrape_type_id: scrapeType.value,
        url_regex: urlRegex.value,
        profile_ids: profiles.value,
        discovery_auto_insert:
          discoveryAutoInsert.value === "" ? false : discoveryAutoInsert.value,
      });
    }

    refetchMatchFilters();
    onClose();
  };

  const isFormValid = () =>
    !(name.value && scrapeType.value && retailer.value && matcher.value);
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={data?.name ? "Update Match Filter" : "Create Match Filter"}
    >
      <div className="row">
        <div className="col-6">
          <Input2 label="Name" name="username" {...name} />
          <TextArea2
            className="mt-3"
            label="Description"
            name="username"
            {...description}
          />

          <AutoComplete
            label="Retailer"
            className="mt-3"
            {...retailer}
            idField="retailer_id"
            func={searchRetailersApi}
            displayValue={data?.retailer_name}
          />
          <AutoComplete
            label="Scrape Type"
            className="mt-3"
            {...scrapeType}
            idField="scrape_type_id"
            func={searchScrapeTypesApi}
            displayValue={data?.scrape_type_name}
          />
          <AutoComplete
            label="Scrape Template"
            className="mt-3"
            {...scrapeTemplate}
            idField="scrape_template_id"
            func={searchScrapeTemplatesApi}
            displayValue={data?.scrape_template_name}
          />
           {data?.scrape_template_id
              ? <Link target="_blank" href={'/scrape_template/' + data?.scrape_template_id}>View Template</Link>
              : ""}

          <AutoComplete
            label="Parse Template"
            className="mt-3"
            idField="parse_template_id"
            {...parseTemplate}
            func={searchParseTemplatesApi}
            displayValue={data?.parse_template_name}
          />
           {data?.parse_template_id
              ? <Link target="_blank" href={'/parse_template/' + data?.parse_template_id}>View Template</Link>
              : ""}
          <AutoComplete
            label="Pilot Template"
            className="mt-3"
            idField="pilot_id"
            {...pilot}
            func={searchPilotSetsApi}
            displayValue={data?.pilot_template_name}
          />
           {data?.pilot_template_id
              ? <Link target="_blank" href={'/pilot/' + data?.pilot_template_id}>View Group</Link>
              : ""}
        </div>
        <div className="col-6">
          <Input2 label="URL Path" name="username" {...url} />
          <Input2
            label="Matcher"
            className="mt-3"
            name="username"
            {...matcher}
          />
          <TextArea2 className="mt-3" label="URL Regex" {...urlRegex} />
          <TextArea2 className="mt-3" label="Profiles" {...profiles} />
          <CheckBox className="mt-3" {...discoveryAutoInsert}>
            Discovery Auto Insert
          </CheckBox>
        </div>
      </div>
      <div className="d-flex mt-4">
        {/* <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-auto mr-3"
          type="button"
          disabled
        >
          Discard
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="mr-3"
          type="button"
          disabled
        >
          Save as Draft
        </Button> */}
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-auto"
          type="button"
          isLoading={createMutation.isLoading}
          onClick={handleCreate}
          disabled={isFormValid()}
        >
          {data?.name ? "Update" : "Create"}
        </Button>
      </div>
    </Modal>
  );
};

const Link = styled.a`
  color:${(props) => props.theme.palette.white};
  font-size:11px;
  position:absolute;
  right:20px;
  &:hover {
    color:${(props) => props.theme.palette.white};
  }
`;


