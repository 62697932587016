import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, Input2, Editor } from "components";
import { useParams } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getPilotSetById, getPilotBySet } from "api";
import { TableHeader, TableContent, ScheduleInfo } from "./components";
import { useQuery } from "react-query";
import { useInput } from "hooks";
import { useDebounce } from "use-debounce";

export const PilotPage = () => {
  const params = useParams();
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const [crawlData, setData] = useState("");
  const searchPilot = useInput("");
  const [debouncedValue] = useDebounce(searchPilot.value, 500);

  const { isLoading, data } = useQuery(
    ["pilot-set", params.id],
    () => getPilotSetById(params.id),
    {
      keepPreviousData: true,
    }
  );

  const {
    isLoading: isPilotsLoading,
    data: pilotsData,
    refetch: pilotsRefetch,
  } = useQuery(
    ["pilots", params.id, debouncedValue],
    () => getPilotBySet(params.id, { search: debouncedValue }),
    {
      keepPreviousData: true,
    }
  );

  const init = async () => {};

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout activeTab="PILOT SETS">
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div>
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <>
              <div>{data?.payload?.name}</div>
              <div className="ml-auto">
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-3"
                >
                  Archive
                </Button>
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-2"
                >
                  Update
                </Button>
              </div>
            </>
          )}
        </Header>
        <ScheduleInfo
          isLoading={isLoading}
          retailer={data?.payload?.retailer_name}
          scrapeType={data?.payload?.scrape_type_name}
          onCreate={() => pilotsRefetch()}
          onCrawl={setData}
        />
        {/* <Type /> */}
        <MainContent>
          <SideSection>
            <Editor value={crawlData} />
          </SideSection>
          <TableSection>
            <div className="d-flex">
              <Input2 label="Search" className="mt-2 flex-1" {...searchPilot} />
              <Button
                buttonTheme="dark"
                width="80px"
                size="small"
                className="mt-4 mb-auto ml-2"
              >
                Search
              </Button>
            </div>

            <TableHeader
              sortCol={sortCol}
              sortType={sortType}
              onSort={handleSort}
            />
            <TableContent
              data={pilotsData?.payload?.results || []}
              isLoading={isPilotsLoading}
              sortCol={sortCol}
              sortType={sortType}
              onUpdate={() => pilotsRefetch()}
            />
          </TableSection>
        </MainContent>
      </MainLayout>
    </SkeletonTheme>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Header = styled.div`
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;
