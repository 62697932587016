import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaPlay, FaTrash } from "react-icons/fa";

import {
  deleteSchedulerById as deleteSchedulerByIdApi,
  runSchedulerById,
} from "api";
// import { Link } from "@reach/router";
import { format } from "date-fns";
import { useSchedulerContext } from "contexts";

export const TableRow = ({ isLoading, data }) => {
  const { deleteSchedulerById } = useSchedulerContext();

  const handleRun = async () => {
    await runSchedulerById(data?.schedule_id);
  };

  const handleDelete = async () => {
    await deleteSchedulerByIdApi(data?.schedule_id);
    deleteSchedulerById(data?.schedule_id);
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    // <Link to={`/schedulers/${data?.schedule_id}`}>
    <Container status={data?.status}>
      <div className="inner">
        {/* <RowItem></RowItem> */}
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.retailer_name}</RowItem>
        <RowItem>{data?.status}</RowItem>
        <RowItem>{}</RowItem>
        <RowItem>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem>
          {data?.last_run_time
            ? format(new Date(data?.last_run_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem>{}</RowItem>
        <RowItem className="d-flex">
          <span>{data?.request_type}</span>
          <div className="ml-auto cursor-pointer mr-4" onClick={handleRun}>
            <FaPlay />
          </div>
          <div className="cursor-pointer mr-2" onClick={handleDelete}>
            <FaTrash />
          </div>
        </RowItem>
      </div>
      {data?.status === "Errored" && (
        <Error>
          Error: Tasks failed, please review task errors <a href="/#">here</a>.
        </Error>
      )}
    </Container>
    // </Link>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "Enabled"
        ? props.theme.palette.success
        : props.status === "Paused"
        ? props.theme.palette.warning
        : props.status === "Disabled"
        ? props.theme.palette.secondary
        : props.status === "Errored"
        ? props.theme.palette.error
        : props.status === "none"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 66px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 120px;
    }
    & > div:nth-child(3) {
      width: 120px;
    }
    & > div:nth-child(4) {
      width: 120px;
    }
    & > div:nth-child(5) {
      width: 120px;
    }
    & > div:nth-child(6) {
      width: 120px;
    }
    & > div:nth-child(7) {
      width: 200px;
    }
    & > div:nth-child(8) {
      width: 200px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

const Error = styled.div`
  height: 40px;
  line-height: 40px;
  background: ${(props) => props.theme.palette.error};
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  padding-left: 40px;
  a {
    color: white;
    text-decoration: underline;
  }
`;
