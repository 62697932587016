import React, { useState } from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export default function FieldRow({ data, active = false, onClick = () => {} }) {
  const [isExpand, setIsExpand] = useState(false);

  const handleExpand = () => {
    onClick(data);
    setIsExpand((state) => !state);
  };

  return (
    <RowContainer onClick={handleExpand} active={active}>
      <RowName>{data?.display_name}</RowName>
      <RowDescription>{data?.description}</RowDescription>
      <DropDown>{!isExpand ? <FaCaretDown /> : <FaCaretUp />}</DropDown>
    </RowContainer>
  );
}

const RowContainer = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
  transition: all 300ms ease;
  margin: 2px 4px 2px 4px;
  background: ${(props) =>
    props.active
      ? props.theme.palette.canvas
      : props.theme.palette.backgrounds};
  font-size: ${(props) => props.theme.font.size.s};
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const RowName = styled.div`
  width: 240px;
  color: white;
`;

const RowDescription = styled.div`
  width: 360px;
  color: white;
  margin-left: 16px;
  margin-right: 16px;
`;

const DropDown = styled.div`
  color: white;
  margin-left; auto;
`;
