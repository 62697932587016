import { API } from "utils";

export const getParseTemplatesApi = async (params) => {
  try {
    const response = await API().get("parse_templates", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const searchParseTemplatesApi = async (search) => {
  try {
    const response = await API().get("parse_templates", {
      params: {
        search,
        status: 'ACTIVE',
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createParseTemplateApi = async (data) => {
  try {
    const response = await API().post(`parse_template`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateParseTemplateApi = async (id, data) => {
  try {
    const response = await API().post(`parse_template/${id}`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const testParseTemplateApi = async (data) => {
  try {
    const response = await API().post(`parse_template_test_url`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getParseTemplateById = async (id) => {
  try {
    const response = await API().get(`parse_template/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteParseTemplateApi = async (id) => {
  try {
    const response = await API().delete(`parse_template/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateParseTemplateName = async (data) => {
  try {
    const response = await API().put(`parse_template/${data.parse_template_id}`, {"name":data.name});
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

