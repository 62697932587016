import React from "react";
import styled from "styled-components";
import { Header, Footer } from "components";

export const MainLayout = ({ children, activeTab = "JOBS" }) => {
  return (
    <StyledLayout>
      <Header active={activeTab} />
      {children}
      <Footer />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${(props) => props.theme.palette.canvas};
`;
