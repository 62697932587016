import React from "react";
import styled from "styled-components";
import { Alert, Button } from "components";
import { useModal } from "hooks";
import { isJSON } from "utils";

export const Editor2 = ({
  value,
  onChange = () => {},
  show,
  onTabClick = () => {},
  onSave = () => {},
  onInitialize = () => {},
  onParse = () => {},
  onValidate = () => {},
  isParsing = false,
  inputType
}) => {
  const validationModal = useModal();
  const handleParse = () => {
    onParse();
  };
  const handleValidation = () => {
    onValidate();
  };

  const handleSave = () => {
    onSave();
  };

  const handleInitialize = () => {
    onInitialize();
  };

  return (
    <div className={`${show ? "d-flex" : "d-none"} flex-column flex-1`}>
      <div className="d-flex mb-2">
        <TabTitle
          className={`my-auto ${!show ? "active" : ""}`}
          onClick={() => {
            if (isJSON(value)) {
              onTabClick("form");
            } else {
              validationModal.openModal();
            }
          }}
        >
          SIMPLE FORM
        </TabTitle>
        <TabTitle className={`my-auto ml-2 ${show ? "active" : ""}`} onClick={() => onTabClick("raw")}>
          RAW JSON
        </TabTitle>

        <Button
          size="small"
          width="140px"
          className="ml-auto"
          buttonTheme="dark"
          onClick={handleInitialize}
        >
          Initialize
        </Button>
        <Button
          size="small"
          width="140px"
          className="ml-2"
          buttonTheme="dark"
          onClick={handleParse}
          isLoading={isParsing}
        >
          Parse
        </Button>
        <Button
          size="small"
          className="ml-2"
          width="120px"
          buttonTheme="dark"
          onClick={handleValidation}
        >
          Validate
        </Button>
        <Button
          size="small"
          className="ml-2"
          width="120px"
          buttonTheme="dark"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      <IDE
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      ></IDE>
      <Alert size="small" title="JSON Validation" {...validationModal}>
        <div>JSON Parse Error</div>
        <div className="d-flex">
          <Button
            size="small"
            className="mt-2 ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => validationModal.onClose()}
          >
            OK
          </Button>
        </div>
      </Alert>
    </div>
  );
};

const IDE = styled.textarea`
  background: ${(props) => props.theme.palette.background};
  flex: 1;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }
`;

const TabTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  text-decoration: underline;
  margin-bottom: 8px;
  padding: 0 0 0 10px;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.primary};
    opacity: 0.7;
  }
  &.active {
    color: ${(props) => props.theme.palette.primary};
    opacity: 0.7;
  }
`;
