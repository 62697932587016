import React from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export const ColumnOrder = ({
  index,
  name,
  onUp = () => {},
  onDown = () => {},
}) => {
  return (
    <Container>
      <Index className="my-auto">{index}</Index>
      <Icon className="ml-2 my-auto">
        <FaCaretUp color="white" onClick={onUp} size={28} />
      </Icon>
      <Icon className="my-auto">
        <FaCaretDown color="white" size={28} onClick={onDown} />
      </Icon>
      <Name className="ml-4 my-auto">{name}</Name>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 2px;
  margin: 2px;
  display: flex;
  background: ${(props) => props.theme.palette.items};
  border: 1px solid ${(props) => props.theme.palette.gray};
  height: 40px;
  padding: 0 12px;
`;

const Index = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  color: white;
  font-size: 12px;
  width: 18px;
  text-align: center;
`;
const Name = styled.div`
  color: ${(props) => props.theme.palette.secondary};
  font-size: 18px;
`;

const Icon = styled.div`
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
