import React from "react";
import styled from "styled-components";
import { Modal, Input } from "components";

export const TaskViewModal = ({ open, onClose, onConfirm = () => {} }) => {
  return (
    <Modal open={open} onClose={onClose} title={"Product 1"}>
      <div className="row">
        <div className="col-6">
          <Input label="Task ID" name="username" />
        </div>
        <div className="col-6">
          <UrlList className="mt-3"></UrlList>
        </div>
      </div>
    </Modal>
  );
};

const UrlList = styled.div`
  height: 300px;
  padding-right: 8px;
  flex: 1;
  margin-right: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
