import React from "react";
import styled from "styled-components";
import { Button, SearchInput } from "components";
export const SearchBar = ({ onSearch = () => {}, isLoading }) => {
  const handleChangeSearch = (params) => {};

  const handleRetryAll = () => {};
  const handleEditScraper = (params) => {};
  const handleEditParser = (params) => {};
  return (
    <Container>
      <LeftPanel>
        <SearchContainer>
          <SearchInput
            placeholder="Search Tasks"
            className="my-auto w-100"
            value={""}
            onChange={handleChangeSearch}
            onEnter={() => onSearch()}
            disabled={isLoading}
          />
        </SearchContainer>
        <Button
          size="normal"
          buttonTheme="dark"
          width="fit-content"
          className="my-auto ml-3"
          disabled={isLoading}
          onClick={() => onSearch()}
        >
          Search
        </Button>
      </LeftPanel>
      <RightPanel>
        <Button
          buttonTheme="dark"
          width="100px"
          size="small"
          className="my-auto"
          onClick={handleRetryAll}
        >
          Retry All
        </Button>
        <Button
          buttonTheme="dark"
          width="120px"
          size="small"
          className="my-auto ml-2"
          onClick={handleEditScraper}
        >
          Edit Scraper
        </Button>
        <Button
          buttonTheme="dark"
          width="120px"
          size="small"
          className="my-auto ml-2"
          onClick={handleEditParser}
        >
          Edit Parser
        </Button>
      </RightPanel>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  margin-bottom: 2px;
`;
const LeftPanel = styled.div`
  flex: 1;
  padding-left: 0px;
  padding-right: 24px;
  display: flex;
`;
const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
const RightPanel = styled.div`
  border-left: 4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 0 0 24px;
  display: flex;
`;
