import { API } from "utils";

export const getTransformGroupsApi = async (params) => {
  try {
    const response = await API().get("transform_groups", {
      params,
    });
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [] };
  }
};

export const getTransformGroupByIdApi = async (id) => {
  try {
    const response = await API().get(`transform_group/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteTransformGroupByIdApi = async (id) => {
  try {
    const response = await API().delete(`transform_group/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createTransformGroupApi = async (req) => {
  const init = {
    name: "",
    output_type: "csv",
    status: "NEW",
    block_order: [],
    key_order: [],
  };
  try {
    const response = await API().post(`transform_group`, { ...init, ...req });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateTransformGroupApi = async (data) => {
  try {
    const response = await API().put(
      `transform_group/${data?.transform_group_id}`,
      data
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
