/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import { COUNT_PER_PAGE } from "config";
import SchedulerReducer from "./SchedulerReducer";
import {
  SET_LOADING,
  INIT_SCHEDULERS,
  GET_SCHEDULERS,
  SET_ERROR,
  GET_SCHEDULER_ID,
  GET_MORE_SCHEDULERS,
  SET_SCHEDULERS_SEARCH,
  SET_SCHEDULERS_FILTER,
  SET_REFETCHING,
  DELETE_SCHEDULER_ID,
} from "./../types";
import { getSchedulersApi, getSchedulerById as getSchedulerByIdApi } from "api";
const SchedulerContext = createContext();
export const useSchedulerContext = () => useContext(SchedulerContext);
const STORAGE_KEY = "armitage-scheduler-state";

export const SchedulerProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    requestType: [],
    retailerFilter: [],
    schedulers: [],
    retailers: [],
    scheduler: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    lastRunDateFrom: "",
    lastRunDateTo: "",
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(SchedulerReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getSchedulers = async () => {
    setSchedulersLoading();
    const res = await getSchedulersApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      request_type: (state?.requestType || []).join(","),
      retailer: (state?.retailerFilter || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      last_run_time_from: state?.lastRunDateFrom,
      last_run_time_to: state?.lastRunDateTo,
    });
    dispatch({
      type: res.success ? GET_SCHEDULERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setSchedulersRefetching();
    const res = await getSchedulersApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      request_type: (state?.requestType || []).join(","),
      retailer: (state?.retailerFilter || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      last_run_time_from: state?.lastRunDateFrom,
      last_run_time_to: state?.lastRunDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_SCHEDULERS : SET_ERROR,
      payload: res.payload,
    });
  };
  const refetchSchedulers = async () => {
    const res = await getSchedulersApi();
    dispatch({
      type: res.success ? GET_SCHEDULERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const getRetailers = async () => {};

  const getSchedulerById = async (id) => {
    setSchedulersLoading();
    const res = await getSchedulerByIdApi(id);
    dispatch({
      type: res.success ? GET_SCHEDULER_ID : SET_ERROR,
      payload: res.payload,
    });
  };

  const deleteSchedulerById = async (id) => {
    dispatch({
      type: DELETE_SCHEDULER_ID,
      payload: id,
    });
  };

  const initSchedulers = async () => {
    dispatch({
      type: INIT_SCHEDULERS,
    });
  };

  // Set Loading
  const setSchedulersLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setSchedulersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_SCHEDULERS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_SCHEDULERS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <SchedulerContext.Provider
      value={{
        ...state,
        initSchedulers,
        setSchedulersLoading,
        getSchedulers,
        getSchedulerById,
        getRetailers,
        refetchSchedulers,
        loadMore,
        setQuery,
        setFilter,
        deleteSchedulerById,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};
