import React from "react";
import styled from "styled-components";
import { Button, Input2, DropDown2 } from "components";
import { useInput } from "hooks";
import { createTransformGroupApi } from "api";
import { useMutation } from "react-query";
// import { isJSON } from "utils";

export const CreateGroup = ({ onCreate = () => {}, onCancel = () => {} }) => {
  const outputExtension = useInput("csv");
  // const columnOrdering = useInput(
  //   '[\n    "title",\n    "store",\n  "url",\n "processed_data"\n]'
  // );
  const name = useInput("");
  const createMutation = useMutation((data) => createTransformGroupApi(data));

  const handleCreate = async () => {
    if (name.value) {
      const res = await createMutation.mutateAsync({
        name: name.value,
        output_type: outputExtension.value,
      });
      onCreate(res?.transform_group_id);
    }
  };
  return (
    <CreateContainer className="row">
      <div className="col-6">
        <Heading>CREATE A NEW GROUP</Heading>
        <Input2
          label="Group Name"
          name="username"
          className="mt-3"
          placeholder="Enter Name"
          {...name}
        />

        <DropDown2
          label="Output Extension"
          className="mt-3"
          idField="retailer_id"
          data={[
            { id: "json", title: "json" },
            { id: "jl", title: "jl" },
          ]}
          placeholder="Select an output extension"
          {...outputExtension}
        />
        <div className="d-flex mt-4">
          <Button
            size="small"
            className="ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            className="ml-2"
            width="120px"
            buttonTheme="dark"
            onClick={() => handleCreate()}
            disabled={
              !name.value || !outputExtension.value
              // !isJSON(columnOrdering.value)
            }
            isLoading={createMutation.isLoading}
          >
            Create
          </Button>
        </div>
      </div>
      {/* <div className="col-6">
        <Heading>OUTPUT COLUMN ORDERING</Heading>
        <Editor
          label="Filter Columns"
          name="username"
          className="mt-3"
          placeholder=""
          style={{ maxHeight: 560, fontSize: 16 }}
          {...columnOrdering}
        />
      </div> */}
    </CreateContainer>
  );
};

const CreateContainer = styled.div`
  flex: 1;
`;

const Heading = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 20px;
`;
