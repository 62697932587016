import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, Button } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useInput } from "hooks";

export const ScheduleInfo = ({ isLoading, status }) => {
  const statusValue = useInput(status);

  useEffect(() => {
    statusValue.onChange(status);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container className="d-flex">
        <div className="d-flex my-auto">
          <Skeleton height={32} width={800} />
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container>
      <div className="row w-100">
        <Input2 label="URL Match" className="col-6 my-auto" />
        <Input2 label="Match Filter" className="col-3 my-auto" />
        <Input2 label="Status" className="col-2 my-auto" {...statusValue} />
        <Button
          buttonTheme="dark"
          width="100px"
          size="small"
          className="ml-3 mt-auto mb-3"
        >
          Archive
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 72px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  padding: 0 24px;
`;
