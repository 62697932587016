import React from "react";
import styled from "styled-components";
import { Button, SearchInput } from "components";
import { CreatePilotSetModal } from "modals";
import { useModal } from "hooks";
import { usePilotSetContext } from "contexts";
export const SearchBar = ({ onSearch = () => {}, isLoading }) => {
  const { query, setQuery, totalCount } = usePilotSetContext();
  const pilotModal = useModal();

  const handleCreateRetailer = () => {
    pilotModal.openModal();
  };

  return (
    <Container>
      <LeftPanel>
        <SearchContainer>
          <SearchInput
            placeholder={`Search ${totalCount} Pilot Sets`}
            className="my-auto w-100"
            value={query}
            onChange={setQuery}
            onEnter={() => onSearch(query)}
            disabled={isLoading}
          />
        </SearchContainer>
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="my-auto ml-3"
          disabled={isLoading}
          onClick={() => onSearch(query)}
        >
          Search
        </Button>
      </LeftPanel>
      <RightPanel>
        <Button
          buttonTheme="dark"
          width="180px"
          size="small"
          className="my-auto"
          disabled={isLoading}
          onClick={handleCreateRetailer}
        >
          Create Pilot Set
        </Button>
      </RightPanel>
      <CreatePilotSetModal {...pilotModal} />
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  margin-bottom: 2px;
`;
const LeftPanel = styled.div`
  flex: 1;
  padding-left: 0px;
  padding-right: 24px;
  display: flex;
`;
const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
const RightPanel = styled.div`
  border-left: 4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 0 0 24px;
  display: flex;
`;
