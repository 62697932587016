/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import TemplateReducer from "./TemplateReducer";
import {
  SET_LOADING,
  INIT_TEMPLATES,
  GET_TEMPLATES,
  SET_ERROR,
  GET_TEMPLATE_ID,
  GET_TEMPLATE_RETAILER,
  SET_REFETCHING,
  GET_MORE_TEMPLATES,
  SET_TEMPLATES_SEARCH,
  SET_TEMPLATES_FILTER,
} from "./../types";
import { COUNT_PER_PAGE } from "config";
import {
  getParseTemplatesApi,
  getTemplateById as getTemplateByIdApi,
  getTemplateByRetailer as getTemplateByRetailerApi,
} from "api";
const TemplateContext = createContext();
export const useTemplateContext = () => useContext(TemplateContext);
const STORAGE_KEY = "armitage-template-state";

export const TemplateProvider = (props) => {
  const initialState = {
    query: "",
    templates: [],
    template: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    lastRunDateFrom: "",
    lastRunDateTo: "",
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(TemplateReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getTemplates = async () => {
    // dispatch({
    //   type: INIT_TEMPLATES,
    // });
    setTemplatesLoading();
    const res = await getParseTemplatesApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      last_run_time_from: state?.lastRunDateFrom,
      last_run_time_to: state?.lastRunDateTo,
    });
    dispatch({
      type: res.success ? GET_TEMPLATES : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setTemplatesRefetching();
    const res = await getParseTemplatesApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      last_run_time_from: state?.lastRunDateFrom,
      last_run_time_to: state?.lastRunDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_TEMPLATES : SET_ERROR,
      payload: res.payload,
    });
  };

  const refetchTemplates = async () => {
    const res = await getParseTemplatesApi();
    dispatch({
      type: res.success ? GET_TEMPLATES : SET_ERROR,
      payload: res.payload,
    });
  };

  const getTemplateByRetailer = async (id) => {
    const res = await getTemplateByRetailerApi(id);
    dispatch({
      type: res.success ? GET_TEMPLATE_RETAILER : SET_ERROR,
      payload: res.payload,
    });
  };

  const getTemplateById = async (id) => {
    setTemplatesLoading();
    const res = await getTemplateByIdApi(id);
    dispatch({
      type: res.success ? GET_TEMPLATE_ID : SET_ERROR,
      payload: res.payload,
    });
  };

  const initTemplates = async () => {
    dispatch({
      type: INIT_TEMPLATES,
    });
  };

  const setQuery = (query) => {
    dispatch({ type: SET_TEMPLATES_SEARCH, payload: query });
  };
  // Set Loading
  const setTemplatesLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setTemplatesRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setFilter = (params) => {
    dispatch({ type: SET_TEMPLATES_FILTER, payload: params });
  };
  const { children } = props;

  return (
    <TemplateContext.Provider
      value={{
        ...state,
        initTemplates,
        setTemplatesLoading,
        getTemplates,
        getTemplateById,
        getTemplateByRetailer,
        refetchTemplates,
        loadMore,
        setTemplatesRefetching,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};
