/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { TAB_DATA } from "config";
import { useAuthContext, useAlertContext } from "contexts";
import { IconButton } from "components";
import { ProfileModal, AlertsModal } from "modals";
import { useModal } from "hooks";
import { abbreviateName } from "utils";
import { IoMdNotifications } from "react-icons/io";
import useInterval from "@rooks/use-interval";

export const Header = ({ active = "JOBS" }) => {
  const profileModal = useModal();
  const alertsModal = useModal();
  const { userInfo } = useAuthContext();
  const {
    alerts = [],
    getAlerts,
    totalCount,
    resultCount,
    setFilter,
  } = useAlertContext();

  const handleNavigateToHome = () => {
    navigate("/jobs");
  };

  const handleLogoClick = (event) => {
    if (event.button === 1) {
      window.open("/jobs");
    }
  };

  const init = async () => {
    //Only GET active alarms for this view.
    setFilter({ status: ["ACTIVE"] });
    getAlerts();
    start();
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const { start } = useInterval(() => {
    getAlerts();
  }, 15000);

  return (
    <StyledHeader>
      <Logo
        className="my-auto"
        onMouseDown={handleLogoClick}
        onClick={handleNavigateToHome}
      >
        {" "}
        Scraper Central
      </Logo>

      <div className="d-flex ml-auto">
        {TAB_DATA.map(({ label, url }) => (
          <Link key={label} href={url} active={active === label}>
            {label}
          </Link>
        ))}

        <div className="position-relative d-flex">
          {resultCount > 0 && <Badge />}
          <IoMdNotifications
            size={28}
            className="my-auto mr-2 cursor-pointer"
            color="white"
            onClick={() => alertsModal.openModal()}
          />
        </div>

        <IconButton
          className="my-auto"
          onClick={() => profileModal.openModal()}
          text={abbreviateName(userInfo?.name)}
          avatar={userInfo?.avatar}
        />
      </div>
      <ProfileModal {...profileModal} userName={userInfo?.user_name} />
      <AlertsModal {...alertsModal} alerts={alerts} totalCount={totalCount} />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
`;

const Logo = styled.div`
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
`;

const Link = styled.a`
  color: ${(props) =>
    props.active ? props.theme.palette.primaryHover : "white"};
  text-decoration: underline;
  font-size: 20px;
  margin: auto 24px auto 0;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
    color: white;
  }
`;

const Badge = styled.div`
  background: ${(props) => props.theme.palette.error};
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  position: absolute;
  text-align: center;
  right: 6px;
  top: 6px;
  border: 2px solid ${(props) => props.theme.palette.backgrounds};
`;
