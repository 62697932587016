import { API } from "utils";
import { COUNT_PER_PAGE } from "config";

export const getJobsApi = async ({
  page = 1,
  size = COUNT_PER_PAGE,
  status,
  creation_time_from,
  creation_time_to,
  complete_time_from,
  complete_time_to,
  search,
}) => {
  try {
    const response = await API().get("jobs", {
      params: {
        page,
        size,
        status,
        creation_time_from,
        creation_time_to,
        complete_time_from,
        complete_time_to,
        search,
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getJobById = async (id) => {
  try {
    const response = await API().get(`job/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const downloadFileAPI = async (id) => {
  try {
    const response = await API().get(`download_file/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
