import React, { useState } from "react";
import styled from "styled-components";

export const TextArea2 = ({
  className,
  label,
  onBlur = () => {},
  value,
  onChange = () => {},
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onBlur(event);
  };
  return (
    <Container className={className}>
      {label && <Label htmlFor={label}>{label}</Label>}

      <div className="d-flex position-relative">
        <StyledTextarea
          id={label}
          onBlur={handleBlur}
          onFocus={() => setFocused(true)}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
        />
        <Underline focused={focused} />
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
`;
const Label = styled.label`
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.palette.secondary};
  display: flex;
  margin-bottom: 0;
`;
const StyledTextarea = styled.textarea`
  display: block;
  color: white;
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.palette.gray};
  transition: background-color 5000s linear 0s;
  width: 100%;
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.m};
  padding: 4px;
  overflow-x: hidden;
  background-color: transparent;
  border: none;

  /* border: 1px solid #e2e2e2; */
  background: transparent;
  height: 160px;
  color: white;
  flex: 1;
  overflow-y: auto;
  outline: none;
  padding: 8px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
  ::-webkit-scrollbar-corner {
    background: #555;
    cursor: pointer;
  }

  &:-internal-autofill-selected {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  /* Needs to be a single selector to work in safari */
  &:-webkit-autofill {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  outline: none;
  &:focus {
    outline: none;
  }

  &::-webkit-contacts-auto-fill-button:hover {
    background-color: rgb(var(--rgbPrimary));
  }
`;
const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
