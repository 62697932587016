import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaEyeSlash } from "react-icons/fa";
import { useAlertContext } from "contexts";
import { format } from "date-fns";
import { useMutation } from "react-query";
import { updateAlertApi } from "api";

export const TableRow = ({ isLoading, data }) => {
  const updateMutation = useMutation((data) => updateAlertApi(data));
  const { getAlerts } = useAlertContext();

  const handleSilence = async () => {
    await updateMutation.mutateAsync(data?.alarm_id);
    getAlerts();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data?.silence_time}>
      <div className="inner">
        {/* <RowItem></RowItem> */}
        <RowItem>            
          {data?.retailer_name
              ? data?.retailer_name
              : "No Retailer"}
        </RowItem>
        <RowItem>{data?.message}</RowItem>
        <RowItem>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem className="d-flex">
          <span>
            {data?.silence_time
              ? format(new Date(data?.silence_time), "MM/dd/yyyy")
              : ""}
          </span>
          <div className="ml-auto d-flex mr-4">
            <span
              className="ml-3 my-auto cursor-pointer"
              onClick={handleSilence}
            >
              <FaEyeSlash />
            </span>
          </div>
        </RowItem>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${ (props) =>
      !props.status 
        ? props.theme.palette.error
        : props.theme.palette.success};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 66px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      width: 300px;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    & > div:nth-child(3) {
      width: 120px;
    }
    & > div:nth-child(4) {
      width: 200px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

