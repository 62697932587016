/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Modal,
  Button,
  Input2,
  DropDown2,
  CreateGroup,
  TransformBlock,
  Alert,
  EditGroup,
} from "components";
import {
  getTransformsApi,
  getTransformGroupsApi,
  deleteTransformGroupByIdApi,
  getTransformGroupByIdApi,
  updateTransformGroupApi,
} from "api";
import { useInput, useModal } from "hooks";
import { useQuery, useMutation } from "react-query";
import { FaBackward, FaForward } from "react-icons/fa";

export const ManageGroupsModal = ({ open, onClose }) => {
  const name = useInput("");
  const filter = useInput("");
  const groupId = useInput("");
  const [currentState, setCurrentState] = useState("modify");
  const [selectedBlock, setSelectedBlock] = useState("");
  const confirmDeleteModal = useModal();

  const [leftBlocks, setLeftBlocks] = useState([]);
  const [rightBlocks, setRightBlocks] = useState([]);

  const [currentGroupData, setCurrentGroupData] = useState({
    transform_group_id: 0,
    name: "",
    output_type: "csv",
    status: "ACTIVE",
    block_order: [],
  });

  const updateMutation = useMutation((data) => updateTransformGroupApi(data));
  const deleteMutation = useMutation((data) =>
    deleteTransformGroupByIdApi(data)
  );
  const { data: fetchedGroupData, refetch: refetchGroups } = useQuery(
    ["createTransformblocks"],
    () => getTransformGroupsApi(),
    {
      initialData: { results: [] },
      keepPreviousData: true,
    }
  );

  const { data: groupBlocksData } = useQuery(
    ["createTransformblocks", groupId.value],
    () => getTransformGroupByIdApi(groupId.value),
    {
      initialData: { block_order: [] },
      keepPreviousData: true,
      enabled: !!groupId.value,
    }
  );
  const {
    data: {
      payload: { results: blocksData },
    },
  } = useQuery(
    ["createGroupTransformblocks"],
    () => getTransformsApi({ size: 9999, status: "ACTIVE" }),
    {
      initialData: { payload: { results: [] } },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (currentGroupData?.block_order) {
      let left = [];
      currentGroupData?.block_order.forEach((block) => {
        if (blocksData.find((item) => item.transform_block_id === block)) {
          left.push(
            blocksData.find((item) => item.transform_block_id === block)
          );
        }
      });
      setLeftBlocks(left);
      setRightBlocks(
        blocksData.filter(
          (block) =>
            !currentGroupData?.block_order.includes(block.transform_block_id)
        )
      );
    } else {
      setLeftBlocks([]);
      setRightBlocks(blocksData);
    }

    return () => {};
  }, [currentGroupData?.block_order]);

  useEffect(() => {
    setCurrentGroupData(groupBlocksData);
    return () => {};
  }, [groupBlocksData]);

  useEffect(() => {
    if (blocksData) {
      setRightBlocks(blocksData);
    }
    return () => {};
  }, [blocksData]);

  useEffect(() => {
    if (open) {
      name.setValue("");
      setCurrentState("modify");
    }
    return () => {};
  }, [open]);

  const handleUpdate = async (id) => {
    refetchGroups();
    groupId.setValue(id);
    setCurrentState("modify");
  };

  const handleCancel = () => {
    setCurrentState("modify");
  };

  const isMoveLeftButtonEnabled =
    rightBlocks.length > 0 &&
    groupId.value &&
    rightBlocks.find(
      (item) => item.transform_block_id === selectedBlock?.transform_block_id
    );

  const isMoveRightButtonEnabled =
    leftBlocks.length > 0 &&
    groupId.value &&
    leftBlocks.find(
      (item) => item.transform_block_id === selectedBlock?.transform_block_id
    );

  const handleMoveLeft = async () => {
    if (isMoveLeftButtonEnabled) {
      const res = await updateMutation.mutateAsync({
        ...currentGroupData,
        block_order: currentGroupData.block_order
          ? [...currentGroupData.block_order, selectedBlock.transform_block_id]
          : [selectedBlock.transform_block_id],
      });
      setCurrentGroupData(res);
      setSelectedBlock({});
    }
  };

  const handleMoveRight = async () => {
    if (isMoveRightButtonEnabled) {
      const res = await updateMutation.mutateAsync({
        ...currentGroupData,
        block_order: currentGroupData.block_order
          ? [
              ...currentGroupData.block_order.filter(
                (block) => block !== selectedBlock.transform_block_id
              ),
            ]
          : []
      });
      setCurrentGroupData(res);
      setSelectedBlock({});
    }
  };

  const handleLeftBlockUp = async (index) => {
    let block_order = [...currentGroupData.block_order];
    let active = block_order.splice(index, 1);
    block_order.splice(index - 1, 0, active[0]);

    const res = await updateMutation.mutateAsync({
      ...currentGroupData,
      block_order,
    });
    setCurrentGroupData(res);
  };

  const handleLeftBlockDown = async (index) => {
    let block_order = [...currentGroupData.block_order];
    let active = block_order.splice(index, 1);
    block_order.splice(index + 1, 0, active[0]);

    const res = await updateMutation.mutateAsync({
      ...currentGroupData,
      block_order,
      status: "ACTIVE",
    });
    setCurrentGroupData(res);
  };

  const handleDeleteGroup = async () => {
    await deleteMutation.mutateAsync(groupId.value);
    refetchGroups();
    confirmDeleteModal.onClose();
  };
  return (
    <Modal size="full" open={open} onClose={onClose} title={"Manage Groups"}>
      {currentState === "modify" ? (
        <div className="row">
          <div className="flex-1">
            <div className="d-flex">
              <Heading>MODIFY GROUP</Heading>
              <Button
                size="small"
                className="ml-auto"
                width="140px"
                buttonTheme="dark"
                onClick={() => setCurrentState("create")}
              >
                Create Group
              </Button>
            </div>

            <div className="d-flex mt-3">
              <DropDown2
                className="flex-1"
                label="Group Select"
                idField="retailer_id"
                data={fetchedGroupData?.results.map((item) => ({
                  id: item.transform_group_id,
                  title: item.name,
                }))}
                placeholder="Select a group"
                {...groupId}
              />
              <Button
                size="small"
                className="ml-2 mt-auto"
                width="140px"
                buttonTheme="dark"
                onClick={() => confirmDeleteModal.openModal()}
                disabled={!groupId.value}
              >
                Delete Group
              </Button>
              <Button
                size="small"
                className="ml-2 mt-auto"
                width="140px"
                buttonTheme="dark"
                onClick={() => setCurrentState("edit")}
                disabled={!groupId.value}
              >
                Edit Group
              </Button>
            </div>
            <ColumnContainer className="mt-2">
              {leftBlocks.map((item, index) => (
                <TransformBlock
                  key={item.transform_block_id}
                  data={item}
                  showArrows
                  isFirst={index === 0}
                  isLast={index + 1 === leftBlocks.length}
                  isActive={
                    item?.transform_block_id ===
                    selectedBlock?.transform_block_id
                  }
                  onClick={() => setSelectedBlock(item)}
                  onUp={() => handleLeftBlockUp(index)}
                  onDown={() => handleLeftBlockDown(index)}
                />
              ))}
            </ColumnContainer>
          </div>
          <div className="mx-4 d-flex flex-column">
            <MoveButton
              className="mt-auto"
              isActive={isMoveLeftButtonEnabled}
              onClick={() => handleMoveLeft("")}
            >
              <FaBackward color="white" />
            </MoveButton>
            <MoveButton
              className="mt-4 mb-auto"
              onClick={() => handleMoveRight("")}
              isActive={isMoveRightButtonEnabled}
            >
              <FaForward color="white" />
            </MoveButton>
          </div>
          <div className="flex-1">
            <Heading>ALL TRANSFORM BLOCKS</Heading>
            <Input2
              label="Filter Transform Blocks"
              name="username"
              className="mt-3"
              placeholder="Type to filter transform blocks"
              {...filter}
            />
            <ColumnContainer className="mt-2">
              {rightBlocks
                .filter((block) =>
                  block.name.toLowerCase().includes(filter.value.toLowerCase())
                )
                .map((item, index) => (
                  <TransformBlock
                    key={`${index}key`}
                    data={item}
                    isActive={
                      item?.transform_block_id ===
                      selectedBlock?.transform_block_id
                    }
                    onClick={() => setSelectedBlock(item)}
                  />
                ))}
            </ColumnContainer>
          </div>
        </div>
      ) : currentState === "create" ? (
        <CreateGroup onCreate={handleUpdate} onCancel={handleCancel} />
      ) : currentState === "edit" ? (
        <EditGroup
          onUpdate={handleUpdate}
          onCancel={handleCancel}
          groupId={groupId.value}
        />
      ) : null}
      <Alert
        size="medium"
        title="Are you sure you want to delete this group?"
        {...confirmDeleteModal}
      >
        <div>
          By choosing this DELETE, this group will be deleted all schedulers
          attached will not have access to this transfor any more.
        </div>
        <div className="d-flex mt-4">
          <Button
            size="small"
            className="mt-2 ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => confirmDeleteModal.onClose()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            className="mt-2 ml-3"
            width="120px"
            buttonTheme="dark"
            onClick={() => handleDeleteGroup()}
            isLoading={deleteMutation.isLoading}
          >
            Delete
          </Button>
        </div>
      </Alert>
    </Modal>
  );
};

const Heading = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 20px;
`;

const ColumnContainer = styled.div`
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const MoveButton = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  opacity: ${(props) => (props.isActive ? 1 : 0.4)};
  &:hover {
    opacity: 0.5;
  }

  svg {
    margin: auto;
  }
`;
