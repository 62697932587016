/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input2, SimpleFormAdd, SimpleForm, Button } from "components";
import { useInput } from "hooks";
import { getFieldsApi } from "api";
import { useQuery } from "react-query";

export const SimpleFormContent = ({
  data = [],
  show,
  onTabClick = () => {},
  onChange = () => {},
  onParse = () => {},
  onValidate = () => {},
  onInitialize = () => {},
  isParsing = false,
  fieldsData = [],
  fieldsError,
  isFieldsError,
  isFieldsLoading,
}) => {
  const [formData, setFormData] = useState([]);
  const simpleFormSearch = useInput("");
  useEffect(() => {
    setFormData(data);
    return () => {};
  }, [data]);

  const handleAddField = (params) => {
    try {
      const maxId = Math.max(...formData.map((a) => a?.id));
      const item = data.find((a) => a.field === params.name);
      const temp = [
        ...formData,
        {
          ...params,
          id: maxId + 1,
          path: item?.path,
          endpoint: item?.endpoint,
          type: item?.type,
        },
      ];
      setFormData(temp);
      onChange(temp);
    } catch (error) {}
  };

  const handleUp = (id) => {
    const tempData = [...formData];
    if (id > 0) {
      let temp = tempData[id - 1];
      tempData[id - 1] = tempData[id];
      tempData[id] = temp;
      setFormData(tempData);
      onChange(tempData);
    }
  };

  const handleDown = (id) => {
    const tempData = [...formData];
    if (id < tempData.length - 1) {
      let temp = tempData[id + 1];
      tempData[id + 1] = tempData[id];
      tempData[id] = temp;
      setFormData(tempData);
      onChange(tempData);
    }
  };

  const handleChangeField = (id, data) => {
    let tempData = [...formData];
    const index = tempData.findIndex((item) => item?.id === id);
    if (index !== -1) {
      tempData[index] = data;
      setFormData(tempData);
      onChange(tempData);
    }
  };

  const handleClose = (id) => {
    const temp = [...formData];
    temp.splice(id, 1);
    setFormData(temp);
    onChange(temp);
  };
  const handleParse = () => {
    onParse();
  };
  const handleValidation = () => {
    onValidate();
  };

  const handleSave = async () => {};

  const handleInitialize = () => {
    onInitialize();
  };

  return (
    <div className={`flex-column flex-1 ${show ? "d-flex" : "d-none"} h-100`}>
      <div className="d-flex mb-2">
        <TabTitle
          className={`my-auto ${show ? "active" : ""}`}
          onClick={() => onTabClick("form")}
        >
          SIMPLE FORM
        </TabTitle>
        <TabTitle
          className={`my-auto ml-2 ${!show ? "active" : ""}`}
          onClick={() => onTabClick("raw")}
        >
          RAW JSON
        </TabTitle>

        <Button
          size="small"
          width="140px"
          className="ml-auto"
          buttonTheme="dark"
          onClick={handleInitialize}
        >
          Initialize
        </Button>
        <Button
          size="small"
          width="140px"
          className="ml-2"
          buttonTheme="dark"
          onClick={handleParse}
          disabled={formData.length === 0}
          isLoading={isParsing}
        >
          Parse
        </Button>
        <Button
          size="small"
          className="ml-2"
          width="120px"
          buttonTheme="dark"
          onClick={handleValidation}
          disabled={formData.length === 0}
        >
          Validate
        </Button>
        <Button
          size="small"
          className="ml-2"
          width="120px"
          buttonTheme="dark"
          onClick={handleSave}
          disabled={formData.length === 0}
        >
          Save
        </Button>
      </div>
      <div className={`mt-3 mb-3 d-flex px-2 ${show ? "d-block" : "d-none"}`}>
        <Input2
          label="Search"
          placeholder="Type to search for field"
          className="flex-1"
          {...simpleFormSearch}
        />
        <SimpleFormAdd
          className="ml-3"
          formData={formData}
          onAdd={handleAddField}
          fieldsData={fieldsData}
          fieldsError={fieldsError}
          isFieldsLoading={isFieldsLoading}
          isFieldsError={isFieldsError}
        />
      </div>
      <FormContent>
        {formData
          .filter((item) =>
            item?.field
              ? item?.field.includes(simpleFormSearch.value)
              : item?.section
              ? item?.section.includes(simpleFormSearch.value)
              : false
          )
          .map((item, index) => (
            <SimpleForm
              data={item}
              index={index + 1}
              key={`${index}key`}
              isLast={formData.length - 1 === index}
              onClose={handleClose}
              onUp={handleUp}
              onDown={handleDown}
              onChange={(e) => handleChangeField(item.id, e)}
              onRawClick={() => onTabClick("raw")}
              disableArrow={simpleFormSearch.value}
            />
          ))}
      </FormContent>
    </div>
  );
};

const FormContent = styled.div`
  background: ${(props) => props.theme.palette.background};
  width: 100%;
  height: 100%;
  flex: 1;
  margin-right: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const TabTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  text-decoration: underline;
  margin-bottom: 8px;
  padding: 0 0 0 10px;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.primary};
    opacity: 0.7;
  }
  &.active {
    color: ${(props) => props.theme.palette.primary};
    opacity: 0.7;
  }
`;
