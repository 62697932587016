import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { format } from "date-fns";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";

export const TransformBlock = ({
  isFirst = false,
  isLast = false,
  isLoading,
  status,
  data,
  isActive = false,
  onClick = () => {},
  showArrows = false,
  onUp = () => {},
  onDown = () => {},
}) => {
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data?.status} isActive={isActive} onClick={onClick}>
      <div className="inner">
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.status}</RowItem>
        <RowItem>{data?.version}</RowItem>
        <RowItem>
          {data?.creation_time
            ? format(new Date(data?.creation_time), "MM/dd/yyyy")
            : ""}
        </RowItem>
        <RowItem className="d-flex">
          <span>
            {data?.update_time
              ? format(new Date(data?.update_time), "MM/dd/yyyy")
              : ""}
          </span>
        </RowItem>
        {showArrows && (
          <RowItem className="d-flex">
            <ArrowButton
              onClick={(e) => {
                e.preventDefault();
                !isFirst && onUp();
              }}
              isActive={!isFirst}
            >
              <FaCaretUp color="white" size={24} />
            </ArrowButton>
            <ArrowButton onClick={() => !isLast && onDown()} isActive={!isLast}>
              <FaCaretDown color="white" size={24} />
            </ArrowButton>
          </RowItem>
        )}
      </div>
      {status === "error" && (
        <Error>
          Error: Tasks failed, please review task errors <a href="/#">here</a>.
        </Error>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) =>
    props.isActive
      ? props.theme.palette.itemsHover
      : props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  cursor: pointer;
  font-size: ${(props) => props.theme.font.size.s};
  border: 1px solid ${(props) => props.theme.palette.gray};
  width: calc(100% - 20px);
  border-left: 6px solid
    ${(props) =>
      props.status === "ACTIVE"
        ? props.theme.palette.success
        : props.status === "ARCHIVED"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 20px;
    height: 48px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & > div:nth-child(2) {
      width: 60px;
    }
    & > div:nth-child(3) {
      width: 80px;
    }
    & > div:nth-child(4) {
      width: 80px;
    }
    & > div:nth-child(5) {
      width: 60px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 6px auto 0;
`;

const Error = styled.div`
  height: 40px;
  line-height: 40px;
  background: ${(props) => props.theme.palette.error};
  color: white;
  font-size: ${(props) => props.theme.font.size.s};
  padding-left: 40px;
  a {
    color: white;
    text-decoration: underline;
  }
`;

const ArrowButton = styled.div`
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  transition: all 300ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
