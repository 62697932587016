import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "components";
import { DateRangePicker } from "react-date-range";
import { sub, format, isAfter } from "date-fns";
import { SPECIAL_DATES } from "config";

export const DateRange = ({
  startDate = "",
  endDate = "",
  onChange = () => {},
  className,
}) => {
  const [uploadDates, setUploadDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [datePickerPos, setDatePickerPos] = useState({ x: 0, y: 0 });
  const buttonsRef = useRef();
  useEffect(() => {
    setUploadDates([
      {
        startDate:
          startDate && startDate === "yesterquater"
            ? sub(new Date(), { months: 3 })
            : startDate && startDate === "yestermonth"
            ? sub(new Date(), { months: 1 })
            : startDate && startDate === "yesterweek"
            ? sub(new Date(), { weeks: 1 })
            : startDate
            ? new Date(startDate)
            : new Date(),
        endDate: endDate ? new Date(endDate) : new Date(),
        key: "selection",
      },
    ]);
    return () => {};
  }, [startDate, endDate]);

  const handleSelectUploadDate = (item) => {
    setUploadDates([item.selection]);
  };

  const handleOk = () => {
    onChange([
      format(new Date(uploadDates[0].startDate), "yyyy-MM-dd"),
      format(new Date(uploadDates[0].endDate), "yyyy-MM-dd"),
    ]);
    setIsDatePickerVisible(false);
  };
  const handleCancel = (params) => {
    setIsDatePickerVisible(false);
  };

  const handleSelectDate = () => {
    const { x, y } = buttonsRef.current.getBoundingClientRect();
    setDatePickerPos({
      x,
      y: y > window.innerHeight - 480 ? window.innerHeight - 480 : y,
    });
    setIsDatePickerVisible(true);
  };
  const handleClear = () => {
    onChange(["", ""]);
    setIsDatePickerVisible(false);
  };

  return (
    <Container className={className}>
      <div className="d-flex mt-0 justify-content-center">
        <Label>From:</Label>
        <DateInput>
          {startDate && !SPECIAL_DATES.includes(startDate)
            ? format(new Date(startDate), "MMM dd yyyy")
            : "-"}
        </DateInput>
      </div>
      <div className="d-flex mt-1 mb-3 justify-content-center">
        <Label>To:</Label>
        <DateInput>
          {endDate ? format(new Date(endDate), "MMM dd yyyy") : "-"}
        </DateInput>
      </div>
      <div className="d-flex mt-2 justify-content-between" ref={buttonsRef}>
        <Button size="small" width="110px" onClick={handleSelectDate}>
          Select Date
        </Button>
        <Button size="small" width="100px" onClick={handleClear}>
          Clear
        </Button>
      </div>
      <div className="position-relative">
        <DateRangeContainer visible={isDatePickerVisible} pos={datePickerPos}>
          <DateRangePicker
            ranges={uploadDates}
            onChange={handleSelectUploadDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            months={1}
            renderStaticRangeLabel={(range) => {
              return range.label;
            }}
            disabledDay={(date) => {
              return isAfter(new Date(date), new Date());
            }}
            inputRanges={[]}
            staticRanges={[
              {
                label: "Yesterday",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { days: 1 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "1 Week",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { weeks: 1 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "1 Month",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { months: 1 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "3 Months",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { months: 3 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "6 Months",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { months: 6 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "1 Year",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { years: 1 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "3 Years",
                hasCustomRendering: true,
                range: () => ({
                  startDate: sub(new Date(), { years: 3 }),
                  endDate: new Date(),
                }),
                isSelected() {
                  return true;
                },
              },
            ]}
          />
          <DateRangeButtons className="d-flex mt-1">
            <Button
              size="small"
              className="ml-auto"
              width="110px"
              onClick={handleOk}
            >
              Ok
            </Button>
            <Button
              size="small"
              className="ml-2"
              width="110px"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </DateRangeButtons>
        </DateRangeContainer>
      </div>
    </Container>
  );
};

const Container = styled.div``;

const Label = styled.div`
  color: white;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  width: 60px;
`;

const DateInput = styled.div`
  width: 100px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
  color: #e2e2e2;
  text-align: center;
`;

const DateRangeContainer = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: ${(props) => `${props.pos.y}px`};
  left: ${(props) => `${props.pos.x}px`};
  width: 560px;
  z-index: 9;
  margin-top: 40px;
  background: white;
`;
const DateRangeButtons = styled.div`
  border-top: 1px solid #e2e2e2;
  padding: 8px 12px 8px 0;
`;
