import { API } from "utils";

export const getPilotsApi = async (params) => {
  try {
    const response = await API().get("pilot_test", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPilotById = async (id) => {
  try {
    const response = await API().get(`pilot_test/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const getPilotBySet = async (id, params) => {
  try {
    const response = await API().get(`pilot_tests/${id}`, { params });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const searchPilotsApi = async (search) => {
  try {
    const response = await API().get("pilot_test", {
      params: {
        search,
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createPilotApi = async (data) => {
  try {
    const response = await API().post("pilot_test", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const removePilotApi = async (id) => {
  try {
    const response = await API().delete(`pilot_test/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
