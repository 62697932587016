import { API } from "utils";

export const getSchedulersApi = async (params) => {
  try {
    const response = await API().get("schedules", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getSchedulerById = async (id) => {
  try {
    const response = await API().get(`schedule/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteSchedulerById = async (id) => {
  try {
    const response = await API().delete(`schedule/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
export const runSchedulerById = async (id) => {
  try {
    const response = await API().get(`run_schedule/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createScheduler = ({ name, payload }) => {
  return API().post(`schedule?name=${name}`, payload);
};
