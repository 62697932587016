import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useJobContext } from "contexts";

import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const statuses = [
  { label: "Staging Request Data", name: "NEW" },
  { label: "Pre Scrapping", name: "CRAWL_PRE" },
  { label: "Scrapping", name: "CRAWL_IP" },
  { label: "Post Scrap", name: "CRAWL_POST" },
  { label: "Pre Parsing", name: "PARSE_PRE" },
  { label: "Parsing", name: "PARSE_IP" },
  { label: "Post Parsing", name: "PARSE_POST" },
  { label: "Pre Deliverable", name: "ETL_PRE" },
  { label: "Deliver/POST Report", name: "ETL_IP" },
  { label: "Post Deliver", name: "ETL_POST" },
  { label: "Finished stage", name: "DONE" },
  { label: "Error", name: "ERROR" },
];

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  const {
    status,
    setFilter,
    creationDateFrom,
    creationDateTo,
    completeDateFrom,
    completeDateTo,
  } = useJobContext();

  const handleFilter = (params) => {
    if (status && status.includes(params)) {
      setFilter({ status: status.filter((a) => a !== params) });
    } else {
      setFilter({ status: [...(status || []), params] });
    }
  };

  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>
          <Accordion
            title="Status"
            desc=""
            className="mb-3"
            open={status && status?.length > 0}
          >
            {statuses.map(({ label, name }) => (
              <CheckBox
                key={name}
                value={(status || []).includes(name)}
                onChange={() => handleFilter(name)}
              >
                {label}
              </CheckBox>
            ))}
          </Accordion>

          {/* <Accordion title="Progress" className="mb-3" open={false}>
            <div className="py-3"></div>
          </Accordion> */}

          <Accordion
            title="Created Date"
            className="mb-3"
            open={creationDateFrom || creationDateTo}
          >
            <div className="py-3">
              <DateRange
                startDate={creationDateFrom}
                endDate={creationDateTo}
                onChange={(dates) => {
                  setFilter({
                    creationDateFrom: dates[0],
                    creationDateTo: dates[1],
                  });
                }}
              />
            </div>
          </Accordion>
          <Accordion
            title="Completed Date"
            className="mb-3"
            open={completeDateFrom || completeDateTo}
          >
            <div className="py-3">
              <DateRange
                startDate={completeDateFrom}
                endDate={completeDateTo}
                onChange={(dates) => {
                  setFilter({
                    completeDateFrom: dates[0],
                    completeDateTo: dates[1],
                  });
                }}
              />
            </div>
          </Accordion>
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
