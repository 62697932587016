import React from "react";
import styled from "styled-components";
import { FaEdit } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "@reach/router";
import { format } from "date-fns";

const LinkStyle = {
  color:"white"
}

export const TableRow = ({ isLoading, status, data }) => {
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
      <Container status={data?.status}>
        <div className="inner">
          {/* <RowItem></RowItem> */}
          <RowItem>{data?.name}</RowItem>
          <RowItem>{data?.version}</RowItem>
          <RowItem>{data?.status}</RowItem>
          <RowItem></RowItem>
          <RowItem>{data?.match_filter_count}</RowItem>
          <RowItem>
            {data?.creation_time
              ? format(new Date(data?.creation_time), "MM/dd/yyyy")
              : ""}
          </RowItem>
          <RowItem className="d-flex">
            {data?.update_time
              ? format(new Date(data?.update_time), "MM/dd/yyyy")
              : ""}
            <div className="ml-auto d-flex mr-4">
              <Link style={LinkStyle} className="my-auto cursor-pointer" to={`/parse_template/${data?.parse_template_id}`}>
                  <FaEdit />
              </Link>
              {/* <span
              className="ml-3 my-auto cursor-pointer"
              //onClick={handleDelete}
              >
                <FaTrashAlt />
              </span> */}
            </div>
          </RowItem>
        </div>
        {status === "error" && (
          <Error>
            Error: Tasks failed, please review task errors <a href="/#">here</a>
            .
          </Error>
        )}
      </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "ACTIVE"
        ? props.theme.palette.success
        : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "info"
        ? props.theme.palette.info
        : props.status === "error"
        ? props.theme.palette.error
        : props.status === "none"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 66px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      width: 300px;
    }
    & > div:nth-child(2) {
      width: 120px;
    }
    & > div:nth-child(3) {
      width: 120px;
    }
    & > div:nth-child(4) {
      width: 300px;
    }
    & > div:nth-child(5) {
      width: 120px;
    }
    & > div:nth-child(6) {
      width: 120px;
    }
    & > div:nth-child(7) {
      flex: 1; 
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

const Error = styled.div`
  height: 40px;
  line-height: 40px;
  background: ${(props) => props.theme.palette.error};
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  padding-left: 40px;
  a {
    color: white;
    text-decoration: underline;
  }
`;
