import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, Input2, Editor, Alert } from "components";
import { useParams, navigate } from "@reach/router";
import {
  getTransformsByIdApi,
  createTransformBlockApi,
  crawlApi,
  transformBlockApi,
  deleteTransformsByIdApi,
  publishTransformApi,
} from "api";
import { FaCaretDown } from "react-icons/fa";
import { useMutation } from "react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useInput, useModal } from "hooks";

export const TransformCodeBlockPage = () => {
  const params = useParams();
  const validationModal = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const publishMutation = useMutation((data) => publishTransformApi(data));
  const createMutation = useMutation((data) => createTransformBlockApi(data));
  const deleteMutation = useMutation((data) => deleteTransformsByIdApi(data));
  const transformMutation = useMutation((data) => transformBlockApi(data));
  const parseMutation = useMutation((data) => crawlApi(data));

  const statusValue = useInput("");
  const url = useInput("");
  const name = useInput("");
  const transformCode = useInput("");
  const transformVariables = useInput("");
  const rawJson = useInput("");
  const transformOutput = useInput("");

  const init = async () => {
    setIsLoading(true);
    let res = {};

    res = await getTransformsByIdApi(params.id);

    statusValue.setValue(res?.status);
    name.setValue(res?.name);
    setIsLoading(false);
    transformVariables.setValue(JSON.stringify(res?.variables, null, 2));
    transformCode.setValue(res?.contents, null, 2);
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleParse = async () => {
    const res = await parseMutation.mutateAsync({ urls: url.value });
    rawJson.setValue(JSON.stringify(res?.payload, null, 2));
  };

  const handleSave = async () => {
    if (name.value) {
      const res = await createMutation.mutateAsync({
        transform_block_id: params.id,
        name: name.value,
        contents: transformCode.value,
        variables: transformVariables.value,
      });
      navigate(`/transform/${res?.transform_block_id}`);
    }
  };

  const handleTransform = async () => {
    const res = await transformMutation.mutateAsync({
      parsed: rawJson.value,
      contents: transformCode.value,
      variables: transformVariables.value,
    });
    transformOutput.setValue(JSON.stringify(res, null, 2));
  };

  const handlePublish = async () => {
    await publishMutation.mutateAsync(params.id);
    navigate(`/transforms`);
  };

  const handleArchive = async () => {
    await deleteMutation.mutateAsync(params.id);
    navigate(`/transforms`);
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout activeTab="TRANSFORMS">
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <Input2 label=" Name" className="col-6 my-auto" {...name} />

              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-auto my-auto"
                onClick={handleArchive}
                isLoading={deleteMutation.isLoading}
              >
                Archive
              </Button>
            </div>
          )}
        </Header>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <ToolbarContainer className="d-flex">
              <div className="d-flex my-auto">
                <Skeleton height={32} width={800} />
              </div>
            </ToolbarContainer>
          </SkeletonTheme>
        ) : (
          <ToolbarContainer>
            <div className="row w-100">
              <Input2 label="URL Match" className="col-6 my-auto" {...url} />

              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-auto mt-auto mb-3"
                onClick={handlePublish}
                isLoading={publishMutation.isLoading}
              >
                Publish
              </Button>
            </div>
          </ToolbarContainer>
        )}
        <MainContent>
          <SideSection>
            <div className="d-flex mb-2">
              <JsonTitle className="my-auto">TRANSFORM CODE</JsonTitle>
              <Button
                size="small"
                className="ml-auto"
                width="120px"
                buttonTheme="dark"
                onClick={handleSave}
                isLoading={createMutation.isLoading}
              >
                Save
              </Button>
            </div>
            <Editor {...transformCode} placeholder="Enter Transform Code" />
            <div className="d-flex mt-3 mb-2">
              <JsonTitle className="my-auto">TRANSFORM VARIABLES</JsonTitle>
            </div>
            <Editor
              {...transformVariables}
              placeholder="Enter Transform Code Variables"
            />
          </SideSection>
          <TableSection>
            <div className="d-flex mb-2">
              <JsonTitle className="my-auto">
                <FaCaretDown color="white" size={30} />
                RAW JSON PARSE
              </JsonTitle>
              <Button
                size="small"
                className="ml-auto"
                width="120px"
                buttonTheme="dark"
                onClick={handleParse}
                isLoading={parseMutation.isLoading}
                disabled={!url.value}
              >
                Parse
              </Button>
            </div>
            <Editor
              {...rawJson}
              placeholder="This will show the output of the URL Match parse in its raw state"
            />
            <div className="d-flex mt-3 mb-2">
              <JsonTitle className="my-auto">
                <FaCaretDown color="white" size={30} />
                TRANSFORM OUTPUT
              </JsonTitle>
              <Button
                size="small"
                className="ml-auto"
                width="120px"
                buttonTheme="dark"
                onClick={handleTransform}
                isLoading={transformMutation.isLoading}
                disabled={
                  !rawJson.value ||
                  !transformVariables.value ||
                  !transformCode.value
                }
              >
                Transform
              </Button>
            </div>
            <Editor
              {...transformOutput}
              placeholder="This will show the output of the transform code applied to the URL Match's raw json"
            />
          </TableSection>
        </MainContent>
        <Alert size="small" title="Validation" {...validationModal}>
          <div>{true ? "Success" : "Error"}</div>
          <div className="d-flex">
            <Button
              size="small"
              className="mt-2 ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={() => validationModal.onClose()}
            >
              OK
            </Button>
          </div>
        </Alert>
      </MainLayout>
    </SkeletonTheme>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const Header = styled.div`
  height: 72px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const JsonTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  margin-bottom: 8px;
  padding: 0 0 0 10px;
  svg {
    margin-top: -6px;
  }
`;

const ToolbarContainer = styled.div`
  height: 72px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  padding: 0 24px;
`;
