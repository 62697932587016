import {
  SET_LOADING,
  SET_ERROR,
  INIT_MATCH_FILTERS,
  GET_MATCH_FILTERS,
  GET_MORE_MATCH_FILTERS,
  SET_REFETCHING,
  SET_MATCH_FILTERS_SEARCH,
  SET_MATCH_FILTERS_FILTER,
  DELETE_MATCH_FILTER,
} from "../types";

export default function MatchFilterReducer(state, action) {
  const { payload } = action;
  let matchFilters = [];
  switch (action.type) {
    case INIT_MATCH_FILTERS:
      return {
        query: "",
        status: [],
        country: [],
        lang: [],
        currency: [],
        matchFilters: [],
        scheduler: {},
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        currentPage: 1,
        isLoading: false,
        isRefetching: false,
        creationDateFrom: "",
        creationDateTo: "",
        filterCountries: [],
        filterLanguages: [],
        filterCurrencies: [],
      };
    case GET_MATCH_FILTERS:
      matchFilters = payload.results;
      return {
        ...state,
        matchFilters,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };
    case DELETE_MATCH_FILTER:
      matchFilters = state.matchFilters.filter(
        (a) => a.match_filter_id !== payload
      );
      return {
        ...state,
        matchFilters,
        isLoading: false,
        isRefetching: false,
        totalCount: state.totalCount - 1,
        resultCount: state.resultCount - 1,
      };

    case GET_MORE_MATCH_FILTERS:
      matchFilters = [...state.matchFilters, ...payload.results];
      return {
        ...state,
        matchFilters,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_MATCH_FILTERS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_MATCH_FILTERS_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
