import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button, Input2 } from "components";
import { createPilotApi, crawlApi } from "api";
import { useMutation } from "react-query";
import { useInput } from "hooks";
import { useParams } from "@reach/router";
import { validURL } from "utils";

export const ScheduleInfo = ({
  isLoading,
  onCreate = () => {},
  onCrawl = () => {},
  retailer,
  scrapeType,
}) => {
  const url = useInput("");
  const retailerName = useInput(retailer);
  const scrapeTypeName = useInput(scrapeType);
  const createMutation = useMutation((data) => createPilotApi(data));
  const params = useParams();
  const crawlMutation = useMutation((data) => crawlApi(data));

  const handleCreate = async () => {
    await createMutation.mutateAsync({
      url: url.value,
      pilot_id: params.id,
    });
    url.onChange("");
    onCreate();
  };

  const handleCrawl = async () => {
    const resp = await crawlMutation.mutateAsync({
      urls: url.value
    });
    onCrawl(JSON.stringify(resp.payload, null, 2));
  };

  const buttomMargin = {
    margin: '0 0 0 20px'
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container className="d-flex">
        <div className="d-flex my-auto">
          <Skeleton height={32} width={800} />
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container>
      <div className="row w-100">
        <div className="col-6 d-flex">
          <Input2 label="URL" className="ml-2 my-auto flex-1" {...url} />
          <Button
            style={buttomMargin}
            buttonTheme="dark"
            width="80px"
            size="small"
            className="mt-4 mb-auto"
            disabled={!validURL(url.value)}
            onClick={handleCrawl}
          >
            Crawl
          </Button>
          <Button
            buttonTheme="dark"
            width="100px"
            size="small"
            className="mt-4 mb-auto ml-2"
            onClick={handleCreate}
            isLoading={createMutation.isLoading}
            disabled={!validURL(url.value)}
          >
            Add Pilot
          </Button>
        </div>
        <div className="col-6 d-flex">
          <Input2
            label="Retailer"
            className="ml-2 my-auto flex-1"
            disabled
            {...retailerName}
          />
          <Input2
            label="Scrape Type"
            className="ml-2 my-auto flex-1"
            disabled
            {...scrapeTypeName}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 72px;
  display: flex;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  padding: 0 24px;
  /* & > div:nth-child(1) {
    width: 320px;
  }
  & > div:nth-child(2) {
    width: 120px;
  }
  & > div:nth-child(3) {
    width: 120px;
  }
  & > div:nth-child(4) {
    flex: 1;
  }
  & > div:nth-child(5) {
    width: 320px;
  }
  & > div:nth-child(6) {
    width: 320px;
  } */
`;
