import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components";
import FieldRow from "./fieldRow";

export const FieldsModal = ({
  className,
  open = false,
  onClose = () => {},
  onAdd = () => {},
  ref,
  data = [],
  isError,
  isLoading,
  error,
}) => {
  const [selectedField, setSelectedField] = useState({});

  const handleAdd = () => {
    onClose();
    onAdd(
      selectedField.field_type === "section" ||
        selectedField.field_type === "sectionfield"
        ? {
            section: selectedField.name,
            section_path: "",
            section_type: "",
            block_path: "",
            block_type: "",
            endpoint: "",
            blocks: [{
              "field": "href",
              "type": "",
              "path": "",
            },
            {
              "field": "image",
              "type": "",
              "path": "",
            },
            {
              "field": "name",
              "type": "",
              "path": "",
            },
            {
              "field": "price",
              "type": "",
              "path": "",
            }],
          }
        : {
            field: selectedField.display_name,
            type: "",
            path: "",
            endpoint: "",
          }
    );
  };

  useEffect(() => {
    if (!open) {
      setSelectedField({});
    }
    return () => {};
  }, [open]);

  return (
    <Container className={className} open={open} ref={ref}>
      <Header>
        <div>NAME</div>
        <div>DESCRIPTION</div>
      </Header>
      <Content>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{error}</div>
        ) : data.length === 0 ? (
          <div className="text-center">No Fields</div>
        ) : (
          data.map((item, index) => (
            <FieldRow
              key={`key${index}`}
              data={item}
              onClick={(e) => setSelectedField(item)}
              active={selectedField?.display_name === item?.display_name}
            />
          ))
        )}
      </Content>
      <div className="d-flex mt-3 pr-2">
        <Button
          buttonTheme="dark"
          width="120px"
          size="small"
          className="ml-auto"
          disabled={!selectedField?.display_name}
          onClick={handleAdd}
        >
          Add Field
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray};
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 4px;
  position: absolute;
  display: ${(props) => (props.open ? "block" : "none")};
  margin-top: 32px;
  margin-left: -520px;
  width: 657px;
  height: 52vh;
  z-index: 1;
`;
const Content = styled.div`
  background: ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  overflow-y: scroll;
  height: 400px;
  border: 4px solid ${(props) => props.theme.palette.canvas};
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
const Header = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  margin-bottom: 8px;
  margin-left: 8px;
  & > div:first-child {
    width: 246px;
  }
`;
