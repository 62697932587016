import {
  SET_LOADING,
  SET_ERROR,
  INIT_ALERTS,
  GET_ALERTS,
  GET_MORE_ALERTS,
  SET_REFETCHING,
  SET_ALERTS_SEARCH,
  SET_ALERTS_FILTER,
} from "../types";

export default function AlertsReducer(state, action) {
  const { payload } = action;
  let alerts = [];
  switch (action.type) {
    case INIT_ALERTS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ALERTS:
      alerts = payload.results;
      return {
        ...state,
        alerts,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };

    case GET_MORE_ALERTS:
      alerts = [...state.alerts, ...payload.results];
      return {
        ...state,
        alerts,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_ALERTS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_ALERTS_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
