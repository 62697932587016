import { API } from "utils";

export const getAlertsApi = async (params) => {
  try {
    const response = await API().get("alarms", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const searchAlertsApi = async (search) => {
  try {
    const response = await API().get("alarm", {
      params: {
        search,
        size: 50,
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createAlertApi = async (data) => {
  try {
    const response = await API().post("alarm", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateAlertApi = async (id) => {
  try {
    const response = await API().put(`alarm/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteAlertApi = async (id) => {
  try {
    const response = await API().delete(`alarm/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
