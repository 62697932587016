/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, Input2, Editor, Alert, AutoComplete } from "components";
import {
  createParseTemplateApi,
  searchRetailersApi,
  searchScrapeTypesApi,
} from "api";
import { useMutation } from "react-query";
import { isJSON, isJsonArray } from "utils";
import { useInput, useModal } from "hooks";
import { navigate } from "@reach/router";

export const CreateParseTemplatePage = () => {
  const validationModal = useModal();
  const varaibleValidationModal = useModal();

  const createMutation = useMutation((data) => createParseTemplateApi(data));

  const contents = useInput(JSON.stringify([{"field": "title", "type": "xpath", "path": "", "endpoint": "", "expect": ""}], null, 2));
  const variables = useInput(JSON.stringify([], null, 2));
  const retailer = useInput("");
  const scrapeType = useInput("");
  const name = useInput("");

  const handleCreate = async () => {
    const resp = await createMutation.mutateAsync({
      retailer_id: retailer.value,
      scrape_type_id: scrapeType.value,
      name: name.value,
      version: 1,
      status: "NEW",
      parse_variables: variables.value,
      parse_template: JSON.parse(contents.value),
      user_id: 1,
    });
    console.log("res", resp);

    if (resp?.parse_template_id) {
      navigate(`/parse_template/${resp?.parse_template_id}`);
    }
  };

  const isValid = () =>
    name.value &&
    retailer.value &&
    scrapeType.value &&
    isJsonArray(contents.value);

  const handleValidation = () => {
    validationModal.openModal();
  };

  const handldVaraibleValidation = () => {
    varaibleValidationModal.openModal();
  };

  return (
    <MainLayout activeTab="PARSE TEMPLATES">
      <Header>
        <div className="d-flex row w-100">
          <Input2 label="Name" className="my-auto col-6" {...name} />
          <AutoComplete
            label="Retailer"
            className="col-3"
            {...retailer}
            idField="retailer_id"
            func={searchRetailersApi}
          />
          <AutoComplete
            label="Scrape Type"
            className="flex-1"
            {...scrapeType}
            idField="scrape_type_id"
            func={searchScrapeTypesApi}
          />
          <Button
            buttonTheme="dark"
            width="100px"
            size="small"
            className="ml-3 my-auto"
            onClick={handleCreate}
            isLoading={createMutation.isLoading}
            disabled={!isValid()}
          >
            Create
          </Button>
        </div>
      </Header>

      <MainContent>
        <SideSection>
          <div className="d-flex mb-2">
            <JsonTitle className="my-auto">RAW JSON</JsonTitle>
            <Button
              size="small"
              className="ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={handleValidation}
              disabled={!contents.value}
            >
              Validate
            </Button>
          </div>
          <Editor {...contents} />
        </SideSection>
        <TableSection>
          <div className="d-flex mb-2">
            <JsonTitle className="my-auto">PARSE VARIABLES</JsonTitle>
            <Button
              size="small"
              className="ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={handldVaraibleValidation}
              disabled={!variables.value}
            >
              Validate
            </Button>
          </div>
          <Editor {...variables} />
        </TableSection>
      </MainContent>
      <Alert size="small" title="Validation" {...validationModal}>
        {isJsonArray(contents.value)}
        <div>{isJsonArray(contents.value) ? "Success" : "Error"}</div>
        <div className="d-flex">
          <Button
            size="small"
            className="mt-2 ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => validationModal.onClose()}
          >
            OK
          </Button>
        </div>
      </Alert>
      <Alert size="small" title="Validation" {...varaibleValidationModal}>
        <div>{isJSON(variables.value) ? "Success" : "Error"}</div>
        <div className="d-flex">
          <Button
            size="small"
            className="mt-2 ml-auto"
            width="120px"
            buttonTheme="dark"
            onClick={() => varaibleValidationModal.onClose()}
          >
            OK
          </Button>
        </div>
      </Alert>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  min-width: 50%;
  width: 50%;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  padding: 8px;
  iframe {
    border: 1px solid black;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const Header = styled.div`
  height: 72px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const JsonTitle = styled.div`
  color: ${(props) => props.theme.palette.white};
  margin-bottom: 8px;
  padding: 0 0 0 10px;
`;
