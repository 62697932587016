/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, DropDown2, Button, Input2 } from "components";
import { Formik, Form } from "formik";
import { useInput } from "hooks";
import * as Yup from "yup";
import { useRetailerContext } from "contexts";
import { useMutation } from "react-query";
import { createRetailerApi, updateRetailerApi } from "api";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const CreateRetailerModal = ({
  open,
  onClose,
  onCreate = () => {},
  data = {},
}) => {
  const name = useInput("");
  const domain = useInput("");
  const country = useInput("");
  const language = useInput("");
  const currency = useInput("");
  const status = useInput("");
  const {
    filterCountries,
    filterLanguages,
    filterCurrencies,
    refetchRetailers,
  } = useRetailerContext();

  const createMutation = useMutation((data) => createRetailerApi(data));
  const updateMutation = useMutation((data) => updateRetailerApi(data));

  useEffect(() => {
    if (open) {
      name.setValue(data?.name || "");
      domain.setValue(data?.domain || "");
      language.setValue(data?.lang || "");
      country.setValue(data?.country || "");
      currency.setValue(data?.currency || "");
      status.setValue(data?.status || "");
    }
    return () => {};
  }, [open]);

  const handleCreate = async () => {
    if (data?.name) {
      await updateMutation.mutateAsync({
        name: name.value,
        domain: domain.value,
        country: country.value,
        lang: language.value,
        currency: currency.value,
        status: status.value,
        id: data?.retailer_id,
      });
    } else {
      await createMutation.mutateAsync({
        name: name.value,
        domain: domain.value,
        country: country.value,
        lang: language.value,
        status: status.value,
        currency: currency.value,
      });
    }
    refetchRetailers();
    onClose();
  };

  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      title={data?.name ? "Update Retailer" : "Create Retailer"}
    >
      <Formik
        initialValues={{
          password: "",
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {}}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input2 label="Retailer Name" name="username" {...name} />
            <Input2 label="Domain" name="domain" {...domain} className="mt-3" />
            <DropDown2
              label="Country"
              className="mt-3"
              data={filterCountries.map((a) => ({ id: a, title: a }))}
              {...country}
            />
            <DropDown2
              className="mt-3"
              label="Language"
              data={filterLanguages.map((a) => ({ id: a, title: a }))}
              {...language}
            />
            <DropDown2
              className="mt-3"
              label="Currency"
              data={filterCurrencies.map((a) => ({ id: a, title: a }))}
              {...currency}
            />

            <DropDown2
              className="mt-3"
              label="Status"
              data={[
                {
                  id: "ACTIVE",
                  title: "Active",
                },
                {
                  id: "WARN",
                  title: "Warn",
                },
                {
                  id: "BLOCK",
                  title: "Block",
                },
                {
                  id: "DISABLED",
                  title: "Disabled",
                },
                {
                  id: "OVERRIDE",
                  title: "Override",
                },
              ]}
              {...status}
            />

            <div className="d-flex mt-4">
              <Button
                size="normal"
                buttonTheme="primary"
                width="fit-content"
                className="ml-auto"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={handleCreate}
                disabled={
                  !(
                    name.value &&
                    currency.value &&
                    country.value &&
                    language.value
                  )
                }
              >
                {data?.name ? "Update Retailer" : "Create Retailer"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
