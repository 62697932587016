/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SchemaLayout } from "layouts";
import ReactJson from 'react-json-view'


const jsonArray = {
  "renders": [
    {  
      "url": "url",
      "status": "status",
      "content": "str(dom)",
      "cookies": [],
      "variables": "variables",
      "count": "attempt",
      "route": "route",
      "redirected_url": "redirected_url",
      "time": "self.time.isoformat()",
      "image": "base64 encoded png image - optional",
      "thumb": "base64 encoded png image - optional"
   }
  ],
  "result": {
    "url": "url",
    "status": "status",
    "variables": "variables",
    "redirected_url": "redirected_url",
    "time": "self.time.isoformat()"
  },
  "log": {
    "version": "1.2",
    "creator": {},
    "browser": {},
    "pages": [],
    "entries": [],
    "comment": ""
  },
  "creator": {
    "name": "Firebug",
    "version": "1.6",
    "comment": ""
  },
  "browser": {
    "name": "Firefox",
    "version": "3.6",
    "comment": ""
  },
  "pages": [
    {
      "startedDateTime": "2009-04-16T12:07:25.123+01:00",
      "id": "page_0",
      "title": "Test Page",
      "pageTimings": {},
      "comment": ""
    }
  ],
  "pageTimings": {
    "onContentLoad": 1720,
    "onLoad": 2500,
    "comment": ""
  },
  "entries": [
    {
      "pageref": "page_0",
      "startedDateTime": "2009-04-16T12:07:23.596Z",
      "time": 50,
      "request": {},
      "response": {},
      "cache": {},
      "timings": {},
      "serverIPAddress": "10.0.0.1",
      "connection": "52492",
      "comment": ""
    }
  ],
  "request": {
    "method": "GET",
    "url": "http://www.example.com/path/?param=value",
    "httpVersion": "HTTP/1.1",
    "cookies": [],
    "headers": [],
    "queryString": [],
    "postData": {},
    "headersSize": 150,
    "bodySize": 0,
    "comment": ""
  },
  "response": {
    "status": 200,
    "statusText": "OK",
    "httpVersion": "HTTP/1.1",
    "cookies": [],
    "headers": [],
    "content": {},
    "redirectURL": "",
    "headersSize": 160,
    "bodySize": 850,
    "comment": ""
  },

  "cookies": [
    {
      "name": "TestCookie",
      "value": "Cookie Value",
      "path": "/",
      "domain": "www.janodvarko.cz",
      "expires": "2009-07-24T19:20:30.123+02:00",
      "httpOnly": false,
      "secure": false,
      "comment": ""
    }
  ],
  "headers": [
    {
      "name": "Accept-Encoding",
      "value": "gzip,deflate",
      "comment": ""
    },
    {
      "name": "Accept-Language",
      "value": "en-us,en;q=0.5",
      "comment": ""
    }
  ],
  "queryString": [
    {
      "name": "param1",
      "value": "value1",
      "comment": ""
    },
    {
      "name": "param1",
      "value": "value1",
      "comment": ""
    }
  ],
  "postData": {
    "mimeType": "multipart/form-data",
    "params": [],
    "text": "plain posted data",
    "comment": ""
  },
  "params": [
    {
      "name": "paramName",
      "value": "paramValue",
      "fileName": "example.pdf",
      "contentType": "application/pdf",
      "comment": ""
    }
  ],
  "content": {
    "size": 33,
    "compression": 0,
    "mimeType": "text/html; charset=utf-8",
    "text": "\n",
    "comment": ""
  },
  "cache": {
    "beforeRequest": null,
    "afterRequest": {
      "expires": "2009-04-16T15:50:36",
      "lastAccess": "2009-16-02T15:50:34",
      "eTag": "",
      "hitCount": 0,
      "comment": ""
    }
  },
  "timings": {
    "blocked": 0,
    "dns": -1,
    "connect": 15,
    "send": 20,
    "wait": 38,
    "receive": 12,
    "ssl": -1,
    "comment": ""
  }
}


export const SchemaPage = () => {

  return (
    <SchemaLayout>
      <Container>
        <Card>
          <Title>DAR Schema 1.4</Title>
          <p>This document is intended to describe the DAR ( Deep Ad Request (Archive)) format that can be used to for scraping, monitoring, and collecting data.</p>

          <Title>What is a DAR file?</Title>
          <p>A DAR file is an extension of a HAR file with the intent to be faster and more reliable. Key differences between a DAR file and standard HAR file are the result and render objects. The render object will hold multiple pages that hold data for an entire crawl. This is not to be confused with HAR pages.</p>
        </Card>
        <Card>
          <Title>DAR Data Structure</Title>
          <p>Similar to HAR files are required to be saved in UTF-8 encoding, other encodings are forbidden. The spec requires that tools support and ignore a BOM and allow them to emit one if they like. </p>

          <Title2>Extended DAR Objects</Title2>
          <ol>
            <li>
              <Bold>renders -</Bold> This represents a list of render pages. This is not to be confused with the pages below.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["renders"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>result -</Bold> This represents a summary of what happens on the page and how the crawler collected data.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["result"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>
            </li>
          </ol>

          <Title2>Original HAR Objects</Title2>
          <ol>
            <li>
              <Bold>log -</Bold> This object represents the root of exported data.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["log"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>creator -</Bold> This represents the creator for the HAR.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["creator"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>browser -</Bold> This represents the browser used to create the HAR.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["browser"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>pages -</Bold> This object represents list of exported pages.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["pages"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>pageTimings -</Bold> This object describes timings for various events (states) fired during the page load. All times are specified in milliseconds. If a time info is not available appropriate field is set to -1.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["pageTimings"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>entries -</Bold> This object represents an array with all exported HTTP requests. Sorting entries by startedDateTime (starting from the oldest) is preferred way how to export data since it can make importing faster. However the reader application should always make sure the array is sorted (if required for the import).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["entries"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>request -</Bold> This object contains detailed info about performed request.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["request"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>response -</Bold> This object contains detailed info about the response.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["response"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>cookies -</Bold> This object contains list of all cookies (used in 'request' and 'response' objects).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["cookies"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>headers -</Bold> This object contains list of all headers (used in 'request' and 'response' objects).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["headers"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>


            </li>
            <li>
              <Bold>queryString -</Bold> This object contains list of all parameters & values parsed from a query string, if any (embedded in 'request' object).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["queryString"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>postData -</Bold> This object describes posted data, if any (embedded in 'request' object).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["postData"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>params -</Bold> List of posted parameters, if any (embedded in 'postData' object).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["params"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>content -</Bold> This object describes details about response content (embedded in 'response' object).
              <CodeContainer>
                <ReactJson
                  src={jsonArray["content"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>cache -</Bold> This objects contains info about a request coming from browser cache.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["cache"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
            <li>
              <Bold>timings -</Bold> This object describes various phases within request-response round trip. All times are specified in milliseconds.
              <CodeContainer>
                <ReactJson
                  src={jsonArray["timings"]}
                  theme="bespin"
                  iconStyle="square"
                  collapsed="true"
                  displayDataTypes="false"
                />
              </CodeContainer>

            </li>
          </ol>
        </Card>
      </Container>
    </SchemaLayout>
  );
};


const CodeContainer = styled.div`
  padding:15px 8px 15px 0px;
`;


const Title = styled.div`
  font-size:20px;
  border-bottom:1px solid ${(props) => props.theme.palette.primaryOutline};
  margin: 0 0 10px 0;
  color:${(props) => props.theme.palette.primaryOutline};
`;

const Bold = styled.span`
  font-weight:bold;
`;

const Title2 = styled.div`
  font-size:18px;
  color:${(props) => props.theme.palette.primaryOutline};
`;

const Container = styled.div`
  width: 800px;
  margin: 60px auto 20px auto;
`;
const Card = styled.div`
  margin:5px 0;
  padding:20px;
  color:  ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette.items};
`;
