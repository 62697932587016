import { API } from "utils";

export const getMatchFiltersApi = async (params) => {
  try {
    const response = await API().get("match_filters", {
      params,
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const searchMatchFiltersApi = async (params) => {
  try {
    const response = await API().get("match_filters", {
      params,
    });
    if (response.data?.results) {
      return response.data.results.map((item) => ({
        id: item.match_filter_id,
        title: item.name,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const createMatchFilterApi = async (data) => {
  try {
    const response = await API().post("match_filter", data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateMatchFilterApi = async (data) => {
  try {
    const response = await API().put(`match_filter/${data?.id}`, data);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteMatchFilterApi = async (id) => {
  try {
    const response = await API().delete(`match_filter/${id}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
