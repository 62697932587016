/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import TransformsReducer from "./TransformsReducer";
import {
  SET_LOADING,
  INIT_TRANSFORMS,
  GET_TRANSFORMS,
  SET_ERROR,
  GET_TRANSFORMS_ID,
  SET_REFETCHING,
  GET_MORE_TRANSFORMS,
  SET_TRANSFORMS_SEARCH,
  SET_TRANSFORMS_FILTER,
} from "../types";
import { COUNT_PER_PAGE } from "config";
import { getTransformsApi, getTransformsByIdApi } from "api";
const TransformsContext = createContext();
export const useTransformsContext = () => useContext(TransformsContext);
const STORAGE_KEY = "armitage-tranforms-state";

export const TransformsProvider = (props) => {
  const initialState = {
    query: "",
    tramsforms: [],
    template: {},
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    archivedDateFrom: "",
    archivedDateTo: "",
    status: [],
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(TransformsReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getTransforms = async () => {
    setTransformsLoading();
    const res = await getTransformsApi({
      page: 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      update_time_from: state?.archivedDateFrom,
      update_time_to: state?.archivedDateTo,
    });
    dispatch({
      type: res.success ? GET_TRANSFORMS : SET_ERROR,
      payload: res.payload,
    });
  };
  const loadMore = async () => {
    setTransformsRefetching();
    const res = await getTransformsApi({
      page: state.currentPage + 1,
      size: COUNT_PER_PAGE,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      update_time_from: state?.archivedDateFrom,
      update_time_to: state?.archivedDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_TRANSFORMS : SET_ERROR,
      payload: res.payload,
    });
  };

  const refetchTransforms = async () => {
    dispatch({
      type: INIT_TRANSFORMS,
    });
    const res = await getTransformsApi();
    dispatch({
      type: res.success ? GET_TRANSFORMS : SET_ERROR,
      payload: res.payload,
    });
  };

  const getTransformById = async (id) => {
    setTransformsLoading();
    const res = await getTransformsByIdApi(id);
    dispatch({
      type: res.success ? GET_TRANSFORMS_ID : SET_ERROR,
      payload: res.payload,
    });
  };

  const initTransforms = async () => {
    dispatch({
      type: INIT_TRANSFORMS,
    });
  };

  const setQuery = (query) => {
    dispatch({ type: SET_TRANSFORMS_SEARCH, payload: query });
  };
  // Set Loading
  const setTransformsLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setTransformsRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setFilter = (params) => {
    dispatch({ type: SET_TRANSFORMS_FILTER, payload: params });
  };
  const { children } = props;

  return (
    <TransformsContext.Provider
      value={{
        ...state,
        initTransforms,
        setTransformsLoading,
        getTransforms,
        getTransformById,
        refetchTransforms,
        loadMore,
        setTransformsRefetching,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </TransformsContext.Provider>
  );
};
