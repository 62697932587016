import React from "react";
import styled from "styled-components";

export const Editor = ({
  value,
  onChange = () => {},
  placeholder,
  ...props
}) => {
  return (
    <IDE
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  );
};

const IDE = styled.textarea`
  background: black;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
