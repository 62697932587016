import {
  SET_LOADING,
  SET_ERROR,
  GET_SCRAPE_TEMPLATES,
  GET_MORE_SCRAPE_TEMPLATES,
  INIT_SCRAPE_TEMPLATES,
  GET_SCRAPE_TEMPLATE_ID,
  GET_SCRAPE_TEMPLATE_RETAILER,
  SET_REFETCHING,
  SET_SCRAPE_TEMPLATES_SEARCH,
  SET_SCRAPE_TEMPLATES_FILTER,
} from "../types";

export default function TemplateReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_SCRAPE_TEMPLATES:
      return {
        query: "",
        templates: [],
        template: {},
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        currentPage: 1,
        isLoading: false,
        isRefetching: false,
        creationDateFrom: "",
        creationDateTo: "",
        lastRunDateFrom: "",
        lastRunDateTo: "",
      };
    case GET_SCRAPE_TEMPLATES:
      return {
        ...state,
        templates: payload.results,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: 1,
      };
    case GET_MORE_SCRAPE_TEMPLATES:
      return {
        ...state,
        templates: [...state.templates, ...payload.results],
        isRefetching: false,
        isLoading: false,
        totalCount: payload.total_count,
        resultCount: payload.result_count,
        totalPages: payload.total_pages,
        currentPage: state.currentPage + 1,
      };
    case GET_SCRAPE_TEMPLATE_RETAILER:
      return {
        ...state,
        template: payload,
        isLoading: false,
      };
    case GET_SCRAPE_TEMPLATE_ID:
      return {
        ...state,
        template: payload,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_SCRAPE_TEMPLATES_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_SCRAPE_TEMPLATES_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
