import React from "react";
import theme from "theme";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import {
  LoginPage,
  JobsPage,
  RegisterPage,
  ResetPage,
  ForgotPage,
  NotFoundPage,
  SchedulersPage,
  TaskPage,
  ScrappingPage,
  ParsingPage,
  RetailersPage,
  MatchFiltersPage,
  PilotSetsPage,
  PilotPage,
  CreateParseTemplatePage,
  CreateScrapeTemplatePage,
  ScrapeTemplatesPage,
  ParseTemplatesPage,
  AlertPage,
  SchemaPage,
  TransformsPage,
  TransformCodeBlockPage,
} from "pages";
import { PrivateRoute, PublicRoute, Route } from "components";
import {
  AuthProvider,
  JobProvider,
  SchedulerProvider,
  TemplateProvider,
  RetailerProvider,
  MatchFilterProvider,
  PilotSetProvider,
  ScrapeTemplateProvider,
  AlertProvider,
  TransformsProvider,
} from "contexts";
import { CookiesProvider } from "react-cookie";
import ReactTooltip from "react-tooltip";

import "theme/all.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <JobProvider>
              <SchedulerProvider>
                <TemplateProvider>
                  <RetailerProvider>
                    <MatchFilterProvider>
                      <PilotSetProvider>
                        <ScrapeTemplateProvider>
                          <AlertProvider>
                            <TransformsProvider>
                              <Router>
                                <Route as={SchemaPage} path="/dar-schema" />
                                <PublicRoute as={LoginPage} path="/" />
                                <PublicRoute as={NotFoundPage} path="/404" />
                                <PublicRoute as={LoginPage} path="/login" />
                                <PublicRoute
                                  as={RegisterPage}
                                  path="/register"
                                />
                                <PublicRoute
                                  as={ResetPage}
                                  path="/reset-password"
                                />
                                <PublicRoute
                                  as={ForgotPage}
                                  path="/forgot-password"
                                />
                                <PrivateRoute as={JobsPage} path="/jobs" />
                                <SchedulersPage
                                  as={SchedulersPage}
                                  path="/schedulers"
                                />
                                <TaskPage
                                  as={TaskPage}
                                  path="/schedulers/:id"
                                />
                                <ScrappingPage
                                  as={ScrappingPage}
                                  path="/scrape_template/:id"
                                />
                                <ParsingPage
                                  as={ParsingPage}
                                  path="/parse_template/:id"
                                />
                                <RetailersPage
                                  as={RetailersPage}
                                  path="/retailers"
                                />
                                <MatchFiltersPage
                                  as={MatchFiltersPage}
                                  path="/match_filters"
                                />
                                <PilotSetsPage
                                  as={PilotSetsPage}
                                  path="/pilot_sets"
                                />
                                <PilotPage as={PilotPage} path="/pilot/:id" />
                                <CreateParseTemplatePage
                                  as={CreateParseTemplatePage}
                                  path="/create_parse_template"
                                />
                                <CreateScrapeTemplatePage
                                  as={CreateScrapeTemplatePage}
                                  path="/create_scrape_template"
                                />
                                <ScrapeTemplatesPage
                                  as={ScrapeTemplatesPage}
                                  path="/scrape_templates"
                                />
                                <ParseTemplatesPage
                                  as={ParseTemplatesPage}
                                  path="/parse_templates"
                                />
                                <AlertPage as={AlertPage} path="/alerts" />
                                <TransformsPage
                                  as={TransformsPage}
                                  path="/transforms"
                                />
                                <TransformCodeBlockPage
                                  as={TransformCodeBlockPage}
                                  path="/transform/:id"
                                />
                              </Router>
                              <ReactTooltip
                                id="player-tools"
                                multiline
                                place="bottom"
                                effect="solid"
                                className="player-tools"
                              />
                            </TransformsProvider>
                          </AlertProvider>
                        </ScrapeTemplateProvider>
                      </PilotSetProvider>
                    </MatchFilterProvider>
                  </RetailerProvider>
                </TemplateProvider>
              </SchedulerProvider>
            </JobProvider>
          </AuthProvider>
        </ThemeProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;
