import React from "react";
import styled from "styled-components";
import { FaUpload, FaSearch, FaList, FaDownload } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { JOB_STATUS_PROGRESS } from "config";

export const Progress = ({ isLoading, status }) => {
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <div className="d-flex">
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={90} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={90} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={90} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
      </div>
    </SkeletonTheme>
  ) : JOB_STATUS_PROGRESS[status] === "Stage" ? (
    <div className="d-flex">
      <Circle type="Stage" status="pending" />
      <Line width={100} />
      <Circle type="Scraping" />
      <Line width={100} />
      <Circle type="Parsing" />
      <Line width={100} />
      <Circle type="Deliverables" />
    </div>
  ) : JOB_STATUS_PROGRESS[status] === "Scraping" ? (
    <div className="d-flex">
      <Circle type="Stage" status="done" />
      <Line width={100} />
      <Circle type="Scraping" status="pending" />
      <Line width={100} />
      <Circle type="Parsing" />
      <Line width={100} />
      <Circle type="Deliverables" />
    </div>
  ) : JOB_STATUS_PROGRESS[status] === "Parsing" ? (
    <div className="d-flex">
      <Circle type="Stage" status="done" />
      <Line width={100} />
      <Circle type="Scraping" status="done" />
      <Line width={100} />
      <Circle type="Parsing" status="pending" />
      <Line width={100} />
      <Circle type="Deliverables" />
    </div>
  ) : JOB_STATUS_PROGRESS[status] === "Deliverables" ? (
    <div className="d-flex">
      <Circle type="Stage" status="done" />
      <Line width={100} />
      <Circle type="Scraping" status="done" />
      <Line width={100} />
      <Circle type="Parsing" status="done" />
      <Line width={100} />
      <Circle type="Deliverables" status="pending" />
    </div>
  ) : (
    <div className="d-flex">
      <Circle type="Stage" status="done" />
      <Line width={100} />
      <Circle status="done" type="Scraping" />
      <Line width={100} />
      <Circle type="Parsing" status="done" />
      <Line width={100} />
      <Circle type="Deliverables" status="done" />
    </div>
  );
};

const Circle = ({ type = "Stage", status = "none" }) => {
  return (
    <div>
      <CircleContainer status={status}>
        {type === "Stage" ? (
          <FaUpload />
        ) : type === "Scraping" ? (
          <FaSearch />
        ) : type === "Parsing" ? (
          <FaList />
        ) : type === "Deliverables" ? (
          <FaDownload />
        ) : null}
      </CircleContainer>
      <CircleText>{type.toUpperCase()}</CircleText>
    </div>
  );
};

const CircleContainer = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid
    ${(props) =>
      props.status === "none"
        ? props.theme.palette.secondary
        : props.theme.palette.accent};
  background: ${(props) =>
    props.status === "done"
      ? props.theme.palette.accent
      : props.theme.palette.secondary};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  svg {
    margin: auto;
    color: ${(props) =>
      props.status === "done"
        ? props.theme.palette.white
        : props.theme.palette.items};
    font-size: 24px;
  }
`;

const CircleText = styled.div`
  font-size: 8px;
  text-align: center;
  color: white;
  margin-top: 5px;
`;
const Line = styled.div`
  height: 5px;
  margin: 25px 2px auto 2px;
  width: ${(props) => props.width}px;
  background: ${(props) => props.theme.palette.secondary};
`;
